import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List } from '@material-ui/core';

import { noop } from '../../util/function';
import Transition from './Transition';
import {
  LeftArrowSelectItem,
  RadioSelectItem,
  RightArrowSelectItem,
  SelectItem,
} from './Items';
import { Heading3, Text1 } from '../../typography';

const UnselectableItem = props => (
  <SelectItem tabIndex={-1} disableRipple {...props} />
);

const Level = ({ node, selected, onExpand, onSelect = noop }) => {
  const { parent } = node;

  return (
    <List>
      {parent && (
        <LeftArrowSelectItem onClick={_ => onExpand(parent, true)}>
          <Text1 gray>{parent.label}</Text1>
        </LeftArrowSelectItem>
      )}
      {node.isUnselectable === true ? (
        <UnselectableItem>
          <Heading3 gray>{node.label}</Heading3>
        </UnselectableItem>
      ) : (
        <RadioSelectItem
          onClick={_ => onSelect(node)}
          checked={node === selected}
        >
          <Heading3 gray>{node.label}</Heading3>
        </RadioSelectItem>
      )}
      {node.children
        .filter(c => !c.children)
        .map((c, i) => (
          <RadioSelectItem
            key={i}
            onClick={_ => onSelect(c)}
            checked={c === selected}
          >
            <Text1 gray>{c.label}</Text1>
          </RadioSelectItem>
        ))}
      {node.children
        .filter(c => c.children)
        .map((c, i) => (
          <RightArrowSelectItem key={i} onClick={_ => onExpand(c)}>
            <Text1 gray>{c.label}</Text1>
          </RightArrowSelectItem>
        ))}
    </List>
  );
};

const TreeSelect = ({ openAt, selected, onSelect }) => {
  const initial = openAt.children ? openAt : openAt.parent;

  const [node, setNode] = useState(initial);
  const [direction, setDirection] = useState('');

  const handleExpand = (n, toParent) => {
    setNode(n);
    setDirection(toParent ? 'right' : 'left');
  };

  return (
    <>
      {openAt && (
        <Transition
          direction={direction}
          onTransitionEnd={() => {
            setDirection('');
          }}
        >
          <Level
            node={node}
            selected={selected}
            onExpand={handleExpand}
            onSelect={onSelect}
          />
        </Transition>
      )}
    </>
  );
};

const nodeShape = PropTypes.shape({
  parent: PropTypes.object,
  children: PropTypes.array,
  label: PropTypes.node,
});

TreeSelect.propTypes = {
  selected: nodeShape,
  openAt: nodeShape.isRequired,
  onSelect: PropTypes.func,
};

export default TreeSelect;
