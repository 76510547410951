import React from 'react';

import { reportError } from './logging';
import { ErrorPageOrChildren } from './components/Errors';
import ErrorMessage from './components/Errors/ErrorMessage';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    reportError(error, info);
  }

  render() {
    const { children, onClickRetry } = this.props;
    const { hasError } = this.state;
    if (onClickRetry) {
      return (
        <ErrorPageOrChildren
          fullPageHeight
          errors={[hasError]}
          title="Something went wrong within the app"
          onClickRetry={onClickRetry}
        >
          {children}
        </ErrorPageOrChildren>
      );
    }
    return hasError ? this.renderError() : children;
  }

  renderError() {
    return <ErrorMessage title="Something went wrong" />;
  }
}
