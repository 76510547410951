/**
 * Map and filter an array at the same time.
 *
 * If the map function returns `undefined` for an item that entry will be
 * skipped in the resulting array.
 *
 * Note: There is no way to to map to `undefined` with this function (because
 * that would skip the item in the result). If you want to do this then use
 * map/filter/reduce manually.
 *
 * @param {any[]} items
 * @param {(item: any) => any}
 * @return {any[]}
 */
export const filterMap = (items, f) =>
  items.reduce((result, item) => {
    const x = f(item);
    if (x !== undefined) {
      result.push(x);
    }
    return result;
  }, []);

/**
 * Creates an array of numbers progressing from `inclusiveLowerBound`
 * up to, but not including, `exclusiveUpperBound`.
 *
 * @param {number} [inclusiveLowerBound]
 * @param {number} exclusiveUpperBound
 * @returns {number[]}
 */
export const range = (inclusiveLowerBound, exclusiveUpperBound) => {
  if (exclusiveUpperBound === undefined) {
    exclusiveUpperBound = inclusiveLowerBound;
    inclusiveLowerBound = 0;
  }

  return Array.from(
    Array(exclusiveUpperBound - inclusiveLowerBound),
    (_, i) => inclusiveLowerBound + i
  );
};

/**
 * @param {array} items
 * @param {string|number} key
 * @returns {Map}
 */
export const index = (items, key) => {
  const map = items.reduce((map, item) => {
    map.set(item[key], item);
    return map;
  }, new Map());
  return map;
};
