import React from 'react';
import { withStyles, List, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Avatar } from '../Icons';
import { Note, Text2, Text3 } from '../../typography';

const styles = theme => ({
  root: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: '100%',
    maxWidth: '70vw',
    paddingLeft: theme.spacing(2),
    paddingRight: `calc(${theme.spacing(2)}px + env(safe-area-inset-right))`,
    paddingTop: 'env(safe-area-inset-top)',
    paddingBottom: 'env(safe-area-inset-bottom)',
    display: 'flex',
    flexDirection: 'column',
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
    height: 108,
    paddingTop: theme.spacing(4),
  },
  userIcon: {
    marginLeft: theme.spacing(1),
    marginRight: 12 + theme.spacing(-2), // Lines up with the icon in AppBar.
  },
  children: {
    flex: 1,
    paddingBottom: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    alignItems: 'baseline',
    paddingBottom: theme.spacing(3),
  },
  version: {
    flex: 1,
  },
  logOutButton: {
    color: 'inherit',
    margin: '-6px -8px',
    textTransform: 'none',
  },
});

const MainMenu = ({
  classes,
  children,
  onClickLogOut,
  showLogOut,
  userName,
}) => {
  const version = process.env.REACT_APP_VERSION;

  return (
    <div className={classes.root}>
      {userName && (
        <div className={classes.userInfo}>
          <Text3 component="span">{userName}</Text3>
          <Avatar className={classes.userIcon} />
        </div>
      )}
      <div className={classes.children}>
        <List>{children}</List>
      </div>
      <div className={classes.footer}>
        <Note className={classes.version}>
          {version ? `Version ${version}` : null}
        </Note>
        {showLogOut && (
          <Button
            variant="outlined"
            color="inherit"
            onClick={onClickLogOut}
            className={classes.logOutButton}
          >
            <Text2>Log out</Text2>
          </Button>
        )}
      </div>
    </div>
  );
};

MainMenu.propTypes = {
  children: PropTypes.node.isRequired,
  onClickLogOut: PropTypes.func,
  userName: PropTypes.string.isRequired,
};

export default withStyles(styles, { name: 'LriMainMenu' })(MainMenu);
