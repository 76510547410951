import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { CSSTransition } from 'react-transition-group';

const extractDuration = theme => theme.transitions.duration.shorter;
const createTransition = theme =>
  theme.transitions.create('transform', { duration: extractDuration(theme) });

const createTransitionClasses = (prefix, amount, theme) => ({
  [prefix]: {
    transform: `translate3d(${amount}px, 0, 0)`,
  },
  [`${prefix}Active`]: {
    transform: 'translate3d(0px, 0, 0)',
    transition: createTransition(theme),
  },
});

const useRightTransitionStyles = makeStyles(theme => ({
  ...createTransitionClasses('appear', -100, theme),
  ...createTransitionClasses('enter', -100, theme),
}));

const useLeftTransitionStyles = makeStyles(theme => ({
  ...createTransitionClasses('appear', 100, theme),
  ...createTransitionClasses('enter', 100, theme),
}));

const Transition = ({
  children,
  appear = false,
  onTransitionEnd,
  direction,
}) => {
  const theme = useTheme();
  const leftTransitionStyles = useLeftTransitionStyles();
  const rightTransitionStyles = useRightTransitionStyles();
  let transitionStyles;
  switch (direction) {
    case 'right':
      transitionStyles = rightTransitionStyles;
      break;
    case 'left':
      transitionStyles = leftTransitionStyles;
      break;
    default:
      transitionStyles = {};
      break;
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <CSSTransition
        appear={appear}
        in={!!direction}
        timeout={extractDuration(theme)}
        classNames={{ ...transitionStyles }}
        onEntered={onTransitionEnd}
      >
        {children}
      </CSSTransition>
    </div>
  );
};

export default Transition;
