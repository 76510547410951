import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

/**
 * @example
 *   const App = () => {
 *     const forceUpdate = useForceUpdate();
 *     return <button onClick={forceUpdate}>Force Re-render</button>
 *   }
 */
export const useForceUpdate = () => {
  const [, setState] = useState();
  // A new object will never be equal to the previous state so this is
  // guaranteed to trigger a re-render.
  const forceUpdate = useCallback(() => setState({}), []);
  return forceUpdate;
};

/**
 * @see https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useMediaQuery = query => {
  const [match, setMatch] = useState(window.matchMedia(query).matches);
  useLayoutEffect(() => {
    const mql = window.matchMedia(query);
    const handleChange = ({ matches }) => setMatch(matches);
    mql.addEventListener('change', handleChange);
    return () => mql.removeEventListener('change', handleChange);
  }, [query]);
  return match;
};
