import PropTypes from 'prop-types';

export const LocationShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const LocationParamsShape = PropTypes.shape({
  channel: PropTypes.string,
  businessModel: PropTypes.string,
});

export const LocationAndParamsShape = PropTypes.shape({
  location: LocationShape.isRequired,
  params: LocationParamsShape.isRequired,
});
