import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Heading3 } from '../typography';
import { IconPlacer } from './Select';
import { ClickIndicator } from './Cards';

const useStyles = props => {
  return makeStyles(
    theme => ({
      root: {
        minHeight: 32,
        marginBottom: theme.spacing(1.5),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: props.rootColor ? props.rootColor : '#000',
      },
      buttonRoot: {
        padding: theme.spacing(0),
        background: theme.palette.background.action,
      },
      sort: {
        fontSize: '2rem',
        color: props.sortColor ? props.sortColor : '#000',
      },
    }),
    { name: 'LriHeader' }
  );
};

const Header = ({ icon, onIconClick, title, style = {} }) => {
  const styles = useStyles(style)();

  if (icon) {
    return (
      <IconPlacer
        iconRightSpacing={0}
        className={styles.root}
        icon={
          <IconButton
            classes={{ root: styles.buttonRoot }}
            onClick={onIconClick}
          >
            <ClickIndicator className={styles.sort} />
          </IconButton>
        }
      >
        <Heading3 component="h6" align="center">
          {title}
        </Heading3>
      </IconPlacer>
    );
  }

  return (
    <Heading3 className={styles.root} component="h6" align="center">
      {title}
    </Heading3>
  );
};

Header.propTypes = {
  icon: PropTypes.bool,
  onIconClick: PropTypes.func,
  title: PropTypes.string.isRequired,
};

export default Header;
