import React from 'react';
import { SvgIcon } from '@material-ui/core';

/**
 * The recommended way is to wrap the SVG path in a <SvgIcon /> MUI component
 * https://material-ui.com/components/icons/#svgicon
 */

export const ClockIcon = props => (
  <SvgIcon viewBox="0 0 23 23" {...props}>
    <path d="M12,5c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S16.411,5,12,5z M12,19c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6 S15.309,19,12,19z"></path>
    <path d="M11 9H13V14H11zM9 2H15V4H9z"></path>
    <path
      transform="rotate(-134.999 19 6)"
      d="M17.586 5H20.413999999999998V7H17.586z"
    ></path>
  </SvgIcon>
);

export const KPIsIcon = props => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <rect x="4" y="4" width="11" height="11" />
    <rect x="17" y="4" width="11" height="11" />
    <rect x="4" y="17" width="11" height="11" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 19H19V26H26V19ZM17 17V28H28V17H17Z"
    />
  </SvgIcon>
);

export const ProductsIcon = props => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <path d="M25.9 28L17.8 28.5L16.7 15H15.2L14.1 28.5L6 28L7.2 4H24.7L25.9 28Z" />
  </SvgIcon>
);

export const CompareIcon = props => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <circle cx="23" cy="16" r="6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 20C11.2091 20 13 18.2091 13 16C13 13.7909 11.2091 12 9 12C6.79086 12 5 13.7909 5 16C5 18.2091 6.79086 20 9 20ZM9 22C12.3137 22 15 19.3137 15 16C15 12.6863 12.3137 10 9 10C5.68629 10 3 12.6863 3 16C3 19.3137 5.68629 22 9 22Z"
    />
  </SvgIcon>
);

export const CollapseButtonIcon = props => (
  <SvgIcon viewBox="0 0 10 6" {...props}>
    <path d="M8.44531 0.433594L9.5 1.48828L5 5.98828L0.5 1.48828L1.55469 0.433594L5 3.87891L8.44531 0.433594Z" />
  </SvgIcon>
);

export const Arrow = props => (
  <SvgIcon viewBox="0 0 14 14" {...props}>
    <path d="M13.0117 6.26172V7.73828H3.87109L8.05469 11.957L7 13.0117L0.988281 7L7 0.988281L8.05469 2.04297L3.87109 6.26172H13.0117Z" />
  </SvgIcon>
);

export const Avatar = props => (
  <SvgIcon viewBox="-3 -3 24 24" {...props}>
    <path d="M3.56979 12.1406C5.41354 11.3906 7.17917 11.0156 8.86667 11.0156C10.5542 11.0156 12.3042 11.3906 14.1167 12.1406C15.9604 12.8594 16.8823 13.8125 16.8823 15V17.0156H0.851044V15C0.851044 13.8125 1.75729 12.8594 3.56979 12.1406ZM11.6792 7.82812C10.8979 8.60938 9.96042 9 8.86667 9C7.77292 9 6.83542 8.60938 6.05417 7.82812C5.27292 7.04688 4.88229 6.10938 4.88229 5.01562C4.88229 3.92188 5.27292 2.98438 6.05417 2.20312C6.83542 1.39063 7.77292 0.984375 8.86667 0.984375C9.96042 0.984375 10.8979 1.39063 11.6792 2.20312C12.4604 2.98438 12.851 3.92188 12.851 5.01562C12.851 6.10938 12.4604 7.04688 11.6792 7.82812Z" />
  </SvgIcon>
);

export const Close = props => (
  <SvgIcon viewBox="0 0 12 12" {...props}>
    <path d="M11.2383 1.81641L7.05469 6L11.2383 10.1836L10.1836 11.2383L6 7.05469L1.81641 11.2383L0.761719 10.1836L4.94531 6L0.761719 1.81641L1.81641 0.761719L6 4.94531L10.1836 0.761719L11.2383 1.81641Z" />
  </SvgIcon>
);

export const Vector = props => (
  <SvgIcon viewBox="0 0 8 12" {...props}>
    <path d="M1.93708 11.833C1.7058 11.8324 1.47987 11.7633 1.28767 11.6344C1.09547 11.5055 0.945577 11.3225 0.856823 11.1085C0.768068 10.8945 0.744413 10.659 0.788829 10.4315C0.833246 10.2041 0.943752 9.99488 1.10646 9.83018L4.63137 6.29786L1.10646 2.77075C0.99261 2.66288 0.901471 2.53324 0.838425 2.3895C0.77538 2.24576 0.741704 2.09082 0.739386 1.93382C0.737068 1.77683 0.766155 1.62095 0.824929 1.47541C0.883704 1.32986 0.970975 1.19758 1.08159 1.08638C1.19221 0.975182 1.32394 0.887313 1.469 0.82796C1.61406 0.768606 1.76951 0.738971 1.92618 0.740803C2.08286 0.742634 2.23758 0.775895 2.38121 0.838623C2.52485 0.901351 2.6545 0.992276 2.7625 1.10603L7.95382 6.30827L2.7625 11.5105C2.65448 11.6197 2.52588 11.7063 2.38418 11.7652C2.24248 11.8242 2.0905 11.8543 1.93708 11.8539" />
  </SvgIcon>
);
export const Sort = props => (
  <SvgIcon viewBox="0 0 18 12" {...props}>
    <path d="M0 6.98438V5.01562H12V6.98438H0ZM0 0H18V2.01562H0V0ZM0 12V9.98438H6V12H0Z" />
  </SvgIcon>
);
export const ArrowDownIcon = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="black"
      fillOpacity="0.04"
    />
    <path
      d="M15.4425 9.97131L12 13.4063L8.5575 9.97131L7.5 11.0288L12 15.5288L16.5 11.0288L15.4425 9.97131Z"
      fill="black"
      fillOpacity="0.3"
    />
  </SvgIcon>
);

export const TrendsLineIcon = props => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <g
      transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
      fill="#ff0000"
      stroke="none"
    >
      <path
        d="M90 320 l0 -230 230 0 c225 0 230 0 230 20 0 20 -5 21 -207 20 l-208
-2 -3 211 c-2 203 -3 211 -22 211 -19 0 -20 -7 -20 -230z"
      />
      <path
        d="M449 529 c-8 -16 -8 -27 1 -44 10 -19 9 -32 -6 -70 -9 -26 -23 -49
-31 -52 -18 -7 -83 44 -83 66 0 42 -61 57 -81 20 -8 -16 -8 -28 0 -45 15 -34
-20 -146 -54 -168 -31 -20 -32 -50 -3 -70 37 -26 84 16 60 55 -14 22 23 155
46 164 23 8 82 -36 82 -62 0 -27 34 -53 61 -46 23 6 35 44 21 69 -14 26 16
110 45 123 25 12 31 49 11 69 -19 19 -56 14 -69 -9z"
      />
    </g>
  </SvgIcon>
);

export const DescendingSortIcon = props => (
  <svg width="23" height="17" viewBox="0 0 13 7" fill="none" {...props}>
    <g clipPath="url(#clip0_58_93)">
      <path
        d="M5.54166 4.375L5.13041 3.96375L3.79166 5.29958V0.583328H3.20833V5.29958L1.86958 3.96083L1.45833 4.375L3.5 6.41666L5.54166 4.375Z"
        fill="#6C50FF"
      />
    </g>
    <g clipPath="url(#clip1_58_93)">
      <path
        d="M6.875 5.25H8.625V4.66667H6.875V5.25ZM6.875 1.75V2.33333H12.125V1.75H6.875ZM6.875 3.79167H10.375V3.20833H6.875V3.79167Z"
        fill="#6C50FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_58_93">
        <rect width="7" height="7" fill="white" />
      </clipPath>
      <clipPath id="clip1_58_93">
        <rect width="7" height="7" fill="white" transform="translate(6)" />
      </clipPath>
    </defs>
  </svg>
);

export const AscendingSortIcon = props => (
  <svg width="23" height="17" viewBox="0 0 13 7" fill="none" {...props}>
    <g clipPath="url(#clip0_58_93)">
      <path
        d="M1.45832 2.625L1.86957 3.03625L3.20832 1.70042L3.20832 6.41667H3.79166V1.70042L5.13041 3.03917L5.54166 2.625L3.49999 0.583338L1.45832 2.625Z"
        fill="#6C50FF"
      />
    </g>
    <g clipPath="url(#clip1_58_93)">
      <path
        d="M6.87502 5.25H8.62502V4.66667H6.87502V5.25ZM6.87502 1.75V2.33333H12.125V1.75H6.87502ZM6.87502 3.79167H10.375V3.20833H6.87502V3.79167Z"
        fill="#6C50FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_58_93">
        <rect
          width="7"
          height="7"
          fill="white"
          transform="matrix(-1 0 0 -1 6.99998 7)"
        />
      </clipPath>
      <clipPath id="clip1_58_93">
        <rect
          width="7"
          height="7"
          fill="white"
          transform="translate(6.00002)"
        />
      </clipPath>
    </defs>
  </svg>
);

Arrow.propTypes = { ...SvgIcon.propTypes };
CollapseButtonIcon.propTypes = { ...SvgIcon.propTypes };
KPIsIcon.propTypes = { ...SvgIcon.propTypes };
ClockIcon.propTypes = { ...SvgIcon.propTypes };
ProductsIcon.propTypes = { ...SvgIcon.propTypes };
CompareIcon.propTypes = { ...SvgIcon.propTypes };
Avatar.propTypes = { ...SvgIcon.propTypes };
Close.propTypes = { ...SvgIcon.propTypes };
Vector.propTypes = { ...SvgIcon.propTypes };
Sort.propTypes = { ...SvgIcon.propTypes };
ArrowDownIcon.propTypes = { ...SvgIcon.propTypes };
DescendingSortIcon.propTypes = { ...SvgIcon.propTypes };
AscendingSortIcon.propTypes = { ...SvgIcon.propTypes };