export class NotOkError extends Error {
  constructor(message, response) {
    super(message);
    this.response = response;
  }
}

/**
 * Fetch with common additional handling.
 *
 * The same as `fetch` with the following additional behaviour:
 * 1) Rejects if a response has a non-2xx response code. The rejection error
 *    will be a `NotOkError` with a `response` property.
 * 2) Automatically parses the response body as JSON and resolves with the
 *    result. Rejects if the body is not valid JSON.
 */
export const okFetch = async (url, options) => {
  const response = await fetch(url, options);
  if (!response.ok) {
    throw new NotOkError(`${url} returned status ${response.status}`, response);
  }
  const data = await response.json();
  return data;
};
