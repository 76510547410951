import { callAll } from '../util/function';

const combineData = (...xs) => {
  const error = xs.some(x => x.error);
  const loading = !error && xs.some(x => x.loading);
  const data = xs.map(x => x.data);
  const refetch = callAll(...xs.map(x => x.refetch));

  return { loading, error, data, refetch };
};

export default combineData;
