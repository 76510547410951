import { transformMetrics_v2 } from './transforms';
import { chooseCurrencyFromMetricsResponses } from './currencySelectors';
import { pairMetrics } from './pairMetrics';

export function transformMetricsPair(
  kpisResponse,
  responseA,
  responseB,
  useUsdAmount,
  includeTaxes,
  periodId,
  useLLY,
  trendsPeriods
) {
  const metricsResponseA = { metrics: responseA.metrics?.total_sales || {} };
  const metricsResponseB = { metrics: responseB.metrics?.total_sales || {} };

  const currency = chooseCurrencyFromMetricsResponses(
    metricsResponseA,
    metricsResponseB,
    useUsdAmount
  );

  const dataA = transformMetrics_v2({
    metricsResponse: metricsResponseA,
    kpiMetaResponse: kpisResponse,
    currency,
    includeTaxes,
    useLLY,
    periodId,
    trendsPeriods,
  });
  const dataB = transformMetrics_v2({
    metricsResponse: metricsResponseB,
    kpiMetaResponse: kpisResponse,
    currency,
    includeTaxes,
    useLLY,
    periodId,
    trendsPeriods,
  });

  const paired = pairMetrics(dataA ?? [], dataB ?? []);
  paired.formattedTimeStamp =
    dataA.formattedTimeStamp ?? dataB.formattedTimeStamp;

  return paired;
}
