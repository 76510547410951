import { isNullOrUndefined } from '../../util/object';

const defaultSort = (a, b) => {
  const ao = !isNullOrUndefined(a.order);
  const bo = !isNullOrUndefined(b.order);
  if (a.promoted !== b.promoted) {
    return a.promoted ? -1 : 1;
  }
  if (ao && bo) {
    return a.order - b.order;
  }
  return ao ? -1 : bo ? 1 : 0;
};

export default defaultSort;
