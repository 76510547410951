import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { SecurityError } from './error';
import { msalConfigScope } from './init';

const SecurityCtx = createContext();

export const getUserAndAccessToken = async instance => {
  const user = await instance?.getAllAccounts();
  let tokenResponse;
  if (user?.length) {
    tokenResponse = await instance.acquireTokenSilent({
      account: instance?.getAllAccounts()[0],
      scopes: [msalConfigScope],
    });
  }
  const userId = user?.[0]?.idTokenClaims?.oid;
  const userName = user?.[0]?.name || 'Null';
  return { accessToken: tokenResponse?.accessToken, user, userId, userName };
};

export const SecurityProvider = ({
  children,
  error,
  instance,
  authRequired,
  initialUser,
}) => {
  const [user, setUser] = useState({
    user: initialUser,
    accessToken: initialUser?.access_token,
    userId: initialUser?.profile?.sub,
    userName: initialUser?.profile?.name,
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      const { user, accessToken, userId, userName } =
        await getUserAndAccessToken(instance);
      setUser({ user, userId, userName, accessToken });
    };

    if (instance) {
      fetchUserDetails();
    }
  }, [instance]);

  return (
    <SecurityCtx.Provider
      value={{
        authRequired,
        user: user?.user,
        instance,
        error,
        accessToken: user?.accessToken,
        userId: user?.userId,
        userName: user?.userName,
      }}
      children={children}
    />
  );
};

SecurityProvider.propTypes = {
  children: PropTypes.node,
  instance: PropTypes.object,
  error: PropTypes.string,
  authRequired: PropTypes.bool,
};

/**
 * @typedef {Object} Security
 * @property {boolean} isAuthenticated
 *   Whether or not the user is logged in.
 * @property {object} error
 *   The error return by the auth server.
 * @property {object} user
 *   The user object as returned by `UserManager.getUser`.
 * @property {object} userManager
 *   A `UserManager` object per the oidc-client package.
 *
 * @see https://github.com/IdentityModel/oidc-client-js/wiki#usermanager
 */

/**
 * @returns {Security}
 */
export const useSecurity = () => {
  const value = useContext(SecurityCtx);
  if (value === undefined) {
    throw new SecurityError(
      `An attempt was made to useSecurity but no context is available.
      Do you need to create a Security component?`
    );
  }

  const { authRequired, instance, error, userId, userName, user, accessToken } =
    value;

  const isAuthenticated =
    (authRequired || (user?.length || 0) > 0) && accessToken;

  return {
    isAuthenticated,
    instance,
    error,
    user,
    accessToken,
    userId,
    userName,
  };
};
