/**
 * Calculate the number of milliseconds for a given combination of seconds,
 * minutes, hours and days.
 */
export const ms = ({ seconds, minutes, hours, days }) => {
  let mul = 1;
  let ms = 0;
  const add = (value, perPrevUnit) => {
    mul *= perPrevUnit;
    ms += (value || 0) * mul;
  };

  add(seconds, 1000);
  add(minutes, 60);
  add(hours, 60);
  add(days, 24);

  return ms;
};
