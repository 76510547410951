import React from 'react';
import { Toolbar, makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import BarButton from './BarButton';
import { Text2, Text3 } from '../../typography';

const useStyles = makeStyles(theme => ({
  bar: {
    backdropFilter: 'blur(20px)',
    background: alpha(theme.palette.background.default, 0.65),
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'center',
  },
  selectPeriodButton: {
    borderRadius: 0,
    flex: 1,
    alignSelf: 'stretch',
    paddingLeft: `calc(${theme.spacing(1.5)}px + env(safe-area-inset-left))`,
    paddingRight: `calc(${theme.spacing(1.5)}px + env(safe-area-inset-right))`,
  },
  subText: {
    paddingLeft: theme.spacing(0.5),
  },
}));

const SecondaryBar = ({ text, subText, onClick }) => {
  const classes = useStyles();

  return (
    <Toolbar
      variant="dense"
      classes={{ root: classes.bar }}
      disableGutters={true}
    >
      {onClick ? (
        <BarButton
          className={classes.selectPeriodButton}
          color="inherit"
          onClick={onClick}
        >
          <Text2 component="span" color="inherit">
            {text}
          </Text2>
          {subText && (
            <Text3 component="span" color="inherit" className={classes.subText}>
              {subText}
            </Text3>
          )}
        </BarButton>
      ) : (
        <Text2 component="span" color="inherit">
          {text}
        </Text2>
      )}
    </Toolbar>
  );
};

SecondaryBar.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default SecondaryBar;
