import { index as indexArray } from './array';
import { clone } from './object';

/**
 * @typedef {object} TreeNode
 * @property {TreeNode[]} children
 */

const endTravesal = Symbol();

/**
 * @typedef {object} TraverseOptions
 * @property {'pre-order'|'post-order'} order
 */

/**
 * @param {TreeNode} node
 * @param {(node: TreeNode) => any} visit
 * @param {Set<TreeNode>} visited
 * @param {TraverseOptions} options
 */
const _traverse = (node, visit, visited, options = { order: 'pre-order' }) => {
  if (!['pre-order', 'post-order'].includes(options.order)) {
    throw new Error(
      `Order must be either "pre-order" or "post-order". ${JSON.stringify(
        options.order
      )} was given.`
    );
  }
  if (visited.has(node)) {
    throw new Error('Cycle detected in tree.');
  }
  visited.add(node);

  const visitChildren = () => {
    if (node.children) {
      for (const child of node.children) {
        _traverse(child, visit, visited, options);
      }
    }
  };
  options.order !== 'pre-order' && visitChildren();
  // Allow visitor function to end the traversal early by returning a value.
  const result = visit(node);
  if (result === endTravesal) {
    return result;
  }
  options.order === 'pre-order' && visitChildren();
};

/**
 * @param {TreeNode} rootNode
 * @param {function} visit
 * @param {object} options
 * @param {"pre-order"|"post-order"} options.order
 */
export const traverse = (rootNode, visit, options) =>
  void _traverse(rootNode, visit, new Set(), options);

/**
 * Search a tree for the first value that satisfies the provided predicate.
 *
 * @param {{ children: object[] }} node
 * @param {(node: object) => boolean} predicate
 * @returns {object | undefined}
 */
export const find = (rootNode, predicate) => {
  let result;
  _traverse(
    rootNode,
    x => {
      if (predicate(x)) {
        result = x;
        return endTravesal;
      }
    },
    new Set()
  );
  return result;
};

export const isBranch = node =>
  Array.isArray(node.children) && node.children.length > 0;

export const isLeaf = node => !isBranch(node);

export const prepend = (node, ...children) => {
  if (!('children' in node)) {
    node.children = [];
  }
  node.children = children.concat(...node.children);
  children.forEach(child => {
    child.parent = node;
  });
};

/**
 * @param {Map<string, { parentId?: string }>} index
 * @returns {{ id: string, parent?: any, children?: any[] }}
 */
export const indexToTree = index => {
  let root;
  for (const [id, item] of index.entries()) {
    item.id = id;
    if (item.parentId && index.has(item.parentId)) {
      item.parent = index.get(item.parentId);
      if (!Array.isArray(item.parent.children)) {
        item.parent.children = [];
      }
      item.parent.children.push(item);
      delete item.parentId;
    } else {
      if (root !== undefined) {
        const rootIds = [root.id, id].map(JSON.stringify).join(' and ');
        throw new Error(`Encountered multiple root nodes: ${rootIds}.`);
      }
      root = item;
    }
  }
  if (index.size > 0 && root === undefined) {
    throw new Error('Did not find a root node.');
  }
  return root;
};

export const arrayToTree = items => {
  const itemsById = indexArray(items.map(clone), 'id');
  const tree = indexToTree(itemsById);
  return tree;
};
