import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, CardContent, Grid } from '@material-ui/core';

import Card from './Card';
import { Note, Heading1 } from '../../typography';

const useStyles = makeStyles(theme => ({
  content: {
    minHeight: 164,
    boxSizing: 'border-box',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: theme.spacing(2, 2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
}));

const SentimentCard = ({ onClick, metric }) => {
  const classes = useStyles();
  const { label, formattedValue, dpy, dpp } = metric;
  const color = `${dpy.sentiment}_card`;

  return (
    <Card color={color} onClick={onClick}>
      <CardContent className={classes.content}>
        <Note bold caps>
          {label}
        </Note>
        <Heading1>{formattedValue}</Heading1>
        <Grid container justify="center">
          <Note bold>{dpy.formattedDelta} vs PY</Note>
          {dpp && <Note bold>{dpp.formattedDelta} vs Plan</Note>}
        </Grid>
      </CardContent>
    </Card>
  );
};

SentimentCard.propTypes = {
  onClick: PropTypes.func,
  metric: PropTypes.object.isRequired,
};

export default SentimentCard;
