import React from 'react';
import { withStyles, ListItem, Switch } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Text2 } from '../../typography';

const listItemStyles = theme => ({
  root: {
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '&$focusVisible': {
      background: 'transparent',
    },
  },
  button: {
    '&:hover': {
      background: 'transparent',
    },
  },
  focusVisible: {},
});

const StyledListItem = withStyles(listItemStyles)(ListItem);

const styles = theme => ({
  switch: {
    marginLeft: theme.spacing(1.5),
    marginRight: 0,
  },
});

const MainMenuOption = ({ classes, label, name, checked, onCheckChange }) => {
  const handleClick = e => {
    e.preventDefault();
    onCheckChange(name, !checked);
  };

  return (
    <StyledListItem
      button
      tabIndex={-1}
      disableGutters
      disableRipple
      onClick={handleClick}
    >
      <Text2 component="span">{label}</Text2>
      <Switch color="default" className={classes.switch} checked={checked} />
    </StyledListItem>
  );
};

MainMenuOption.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onCheckChange: PropTypes.func,
};

export default withStyles(styles, { name: 'LriMainMenuOption' })(
  MainMenuOption
);
