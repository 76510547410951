import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';

import { Heading4, Note } from '../../typography';
import ErrorMessage from './ErrorMessage';

const styles = theme => ({
  root: {
    background: theme.palette.background.default,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: ({ fullPageHeight }) => (fullPageHeight ? '100vh' : '100%'),
    padding: theme.spacing(8, 1),
    position: 'relative',
  },
  retry: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.default,
    paddingLeft: theme.spacing(5.5),
    paddingRight: theme.spacing(5.5),
    height: 32,
    width: 113,
    marginTop: theme.spacing(2),
  },
  version: {
    position: 'absolute',
    bottom: theme.spacing(3),
  },
});

const ErrorPage = ({
  classes,
  className,
  message,
  onClickRetry,
  title,
  version,
}) => {
  return (
    <div className={cx(className, classes.root)}>
      <ErrorMessage title={title} message={message} />
      <Button
        className={classes.retry}
        variant="contained"
        onClick={onClickRetry}
      >
        <Heading4>Retry</Heading4>
      </Button>
      {version ? (
        <Note className={classes.version}>Version {version}</Note>
      ) : null}
    </div>
  );
};

ErrorPage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  message: PropTypes.node,
  title: PropTypes.string,
  onClickRetry: PropTypes.func.isRequired,
  version: PropTypes.string,
};

export default withStyles(styles, { name: 'LriErrorPage' })(ErrorPage);
