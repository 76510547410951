import React from 'react';
import { List, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { noop } from '../../util/function';
import { RadioSelectItem } from './Items';
import { Text2 } from '../../typography';

const StyledRadioSelectItem = withStyles(t => ({
  root: {
    minHeight: t.spacing(8),
  },
}))(RadioSelectItem);

const Select = ({ selected, onSelect = noop, items }) => {
  return (
    <List>
      {items.map(item => (
        <StyledRadioSelectItem
          key={item.id}
          onClick={_ => onSelect(item)}
          checked={Boolean(selected) && selected.id === item.id}
        >
          <Text2 gray>{item.label}</Text2>
        </StyledRadioSelectItem>
      ))}
    </List>
  );
};

const itemShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
});

Select.propTypes = {
  selected: itemShape,
  onSelect: PropTypes.func,
  items: PropTypes.arrayOf(itemShape).isRequired,
};

export default Select;
