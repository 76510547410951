import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Collapse } from '@material-ui/core';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Text3 } from '../typography';
import * as colors from '../theme/colors';
import { isNullOrUndefined } from '../util/object';
import PerformanceItemView from './PerformanceItemView';

// import ProductListJSON from '../data/ProductList.json';

const styles = theme => ({
  root: { width: '100%' },
  product: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    width: '100%',
    '& > *': {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1, 0.25, 1, 1),
      verticalAlign: 'top',
    },
    '& > :first-child': {
      paddingLeft: 0,
    },
    '& > :last-child': {
      paddingLeft: 0,
    },
  },
  detailsCell: {
    flex: 1,
    // min-width must be specified for overflow-wrap to work in a flex layout.
    // 0 does the job but 80px is a reasonable value for a full length PC9.
    minWidth: 80,
    overflowWrap: 'break-word',
    '& > :first-child': {
      marginBottom: theme.spacing(0.5),
    },
    '& span': {
      padding: '2px',
    },
  },
  metricsCell: {
    alignItems: 'flex-end',
    '& > :first-child': {
      marginBottom: theme.spacing(0.5),
    },
    '& > span:first-child': {
      marginRight: theme.spacing(1),
    },
  },
  avatar: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.panel,
  },
  avatarBroken: {
    borderColor: 'transparent',
  },
  avatarBrokenIcon: {
    color: theme.palette.action.active,
  },
  collapseExpandBtnContainer: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    '& .btn': {
      cursor: 'pointer',
    },
    '& .text': {
      color: colors.dark2,
      fontSize: 11,
    },
    '& .icon': {
      verticalAlign: 'middle',
      backgroundColor: '#f3f3f3',
      borderRadius: '50%',
      marginLeft: 10,
    },
  },
});

const ListRenderer = ({ item, classes, performanceType }) => {
  const isPerformanceTypeSales = performanceType === 'by_sales';

  return (
    <div className={classes.product}>
      <div className={classes.avatarCell}>
        <PerformanceItemView item={item} />
      </div>
      <div className={classes.detailsCell}>
        <Text3 component="span">{item.name}</Text3>
        <Text3 component="span" gray>
          {item.id}
        </Text3>
        <Text3 component="span" gray>
          {isNullOrUndefined(item.inventoryUnits?.value)
            ? 'N/A'
            : item.inventoryUnits?.formattedValue +
            ' in Stock'}
        </Text3>
      </div>
      <div className={classes.metricsCell}>
        {item.sales && isPerformanceTypeSales && (
          <Text3
            bold={isPerformanceTypeSales}
            gray={!isPerformanceTypeSales}
            component="span"
          >
            {item.sales.formattedValue}
          </Text3>
        )}
        {item.units && (
          <Text3 bold={!isPerformanceTypeSales} gray={isPerformanceTypeSales}>
            {isNullOrUndefined(item.units.value)
              ? 'N/A'
              : item.units.value === 1
                ? `${item.units.formattedValue} unit Sold`
                : `${item.units.formattedValue} units Sold`}
          </Text3>
        )}
        {item.sales && !isPerformanceTypeSales && (
          <Text3
            bold={isPerformanceTypeSales}
            gray={!isPerformanceTypeSales}
            component="span"
          >
            {item.sales.formattedValue}
          </Text3>
        )}
      </div>
    </div>
  );
};

const ProductList = ({ classes, items, performanceType }) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const newItemsList = JSON.parse(JSON.stringify(items));
  const first5List = newItemsList.slice(0, 5);
  const next15List = newItemsList.slice(5);

  return (
    <div className={classes.root}>
      {first5List &&
        first5List.map(item => (
          <ListRenderer
            key={item.id}
            item={item}
            classes={classes}
            performanceType={performanceType}
          />
        ))}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {next15List &&
          next15List.map(item => (
            <ListRenderer
              key={item.id}
              item={item}
              classes={classes}
              performanceType={performanceType}
            />
          ))}
      </Collapse>
      <div className={classes.collapseExpandBtnContainer}>
        {expanded ? (
          <span className="btn" onClick={handleExpandClick}>
            <span className="text">View less products</span>
            <ExpandLessIcon className="icon" fontSize="large" />
          </span>
        ) : (
          <span className="btn" onClick={handleExpandClick}>
            <span className="text">View more products</span>
            <ExpandMoreIcon className="icon" fontSize="large" />
          </span>
        )}
      </div>
    </div>
  );
};

ProductList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      imgSrc: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      units: PropTypes.shape({
        formattedValue: PropTypes.string.isRequired,
      }),
      sales: PropTypes.shape({
        formattedValue: PropTypes.string.isRequired,
      }),
    })
  ),
  performanceType: PropTypes.string.isRequired,
};

export default withStyles(styles, { name: 'LriProductList' })(ProductList);
