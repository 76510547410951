import { formatPercentage, formatPercentagePoints } from '../../format';

const percentagePointsFormatter = delta =>
  formatPercentagePoints(delta, { alwaysShowSign: true });

const percentageFormatter = delta =>
  formatPercentage(delta, { maximumFractionDigits: 1, alwaysShowSign: true });

/**
 * Delta previous year
 *
 * @typedef {Object} Dpy
 * @property {string} formattedDelta - Delta value in percentage
 * @property {("good"|"bad")} sentiment - Interpretation of the value
 * @property {number} delta - Interpretation of the value
 */

/**
 * Computes how the first value compares with the second
 *
 * @param {number} valueA Current value for comparison
 * @param {number} valueB Base value for comparison
 * @param {string} format
 * @param {("high"|"low")} compare Indicates how should the result be interpreted
 * @returns {Dpy} Computed dpy
 */
export const computeDpy = (valueA, valueB, format, compare) => {
  valueA = valueA ?? 0;
  valueB = valueB ?? 0;

  let delta;
  let formatter;
  let formattedDelta;
  let sentiment;

  if (format === 'percentage' || format === 'percentage-points') {
    delta = valueA - valueB;
    formatter = percentagePointsFormatter;
  } else {
    if (valueA === 0 && valueB === 0) {
      // `0 / 0` would result in NaN.
      delta = 0;
    } else {
      // Allow any other `x / 0` resulting in infinity.
      delta = (valueA - valueB) / valueB;
    }
    formatter = percentageFormatter;
  }
  if (!isFinite(delta)) {
    formattedDelta = formatter(delta);
    sentiment = 'neutral';
  } else {
    if (delta > -0.0005 && delta < 0.0005) {
      // A delta this small gets rounded to 0.
      formattedDelta = formatter(0);
      sentiment = 'good';
    } else {
      formattedDelta = formatter(delta);
      const factor = compare === 'high' ? 1 : -1;
      sentiment = delta * factor >= 0 ? 'good' : 'bad';
    }
  }
  return { delta, formattedDelta, sentiment };
};
