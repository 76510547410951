import { formatMoney, formatPercentage } from '../../format';
import { isNullOrUndefined } from '../../util/object';
import { computeDpy } from './computeDpy';
import {
  replaceWithTaxMetricIfNeeded,
  collapseToCurrency,
  attachDpy,
  formatValues,
  selectDisplayFields,
  standardizeMetricsResponseArrays,
  idMapToArray,
  attachAndFormatTimeStamp,
} from './transforms';
import { semanticPeriodsWithValidation } from './periods';
import { selectCurrencyFromStocksResponse } from './currencySelectors';

/**
 * @returns
 *  {
 *    ["men" | "women"]: {
 *      panels: [
 *        {
 *          panelName: "Tops"
 *          sections: [
 *            sectionName: "Tops (Mainline, Outlet)"
 *            periods: null,
 *            metrics: standardizeMetricsResponseArrays({section}, kpiMetaResponse)
 *          ]
 *        }
 *      ]
 *    }
 *  }
 */
function standardizeStocksResponse(
  stockResponse,
  kpiMetaResponse,
  periodId,
  useLLY
) {
  const segments = [
    { id: 'mens', toId: 'men', label: 'Men' },
    { id: 'womens', toId: 'women', label: 'Women' },
    { id: 'boys', toId: 'boys', label: 'Boys' },
    { id: 'girls', toId: 'girls', label: 'Girls' },
  ];
  const categories = [
    { id: 'tops', label: 'Tops' },
    { id: 'bottoms', label: 'Bottoms' },
    { id: 'accessories', label: 'Accessories' },
    { id: 'footwear', label: 'Footwear' },
  ];

  const byPeriod = stockResponse.sales_and_inventory.total_sales;
  const { currentPeriod, previousPeriod } = semanticPeriodsWithValidation(
    byPeriod,
    useLLY,
    periodId
  );

  const result = {};
  for (const segment of segments) {
    result[segment.toId] = { panels: [] };
    for (const category of categories) {
      try {
        const currMetrics = byPeriod[currentPeriod][segment.id][category.id];
        let prevMetrics;
        if (previousPeriod && byPeriod[previousPeriod])
          prevMetrics = byPeriod[previousPeriod][segment.id][category.id];

        result[segment.toId].panels.push({
          panelName: category.label,
          sections: [
            {
              // sectionName: (Mainline | Outlet), extract this from API response when available
              metrics: standardizeMetricsResponseArrays(
                idMapToArray(currMetrics),
                prevMetrics && idMapToArray(prevMetrics),
                kpiMetaResponse
              ),
            },
          ],
        });
      } catch (e) {
        console.error(
          `Failed to create panel for ${segment.id}/${category.id}. Data =`,
          byPeriod,
          e
        );
      }
    }
  }

  return result;
}

function selectDisplayFieldsAndKeepPrev(metrics) {
  return metrics.map(m => ({
    ...selectDisplayFields(m),
    prevYearValue: m.appValue.prevYearAmount,
  }));
}

function processMetrics(metrics, currency, includeTaxes) {
  let result = metrics;
  result = replaceWithTaxMetricIfNeeded(result, includeTaxes);
  result = result.map(x => collapseToCurrency(x, currency));
  result = attachDpy(result);
  result = result.map(formatValues);
  result = selectDisplayFieldsAndKeepPrev(result);
  return result;
}

function convertToDisplayValues(genderStock, currency, includeTaxes) {
  return {
    ...genderStock,
    panels: genderStock.panels.map(panel => ({
      ...panel,
      sections: panel.sections.map(section => ({
        ...section,
        periods: null,
        metrics: processMetrics(section.metrics, currency, includeTaxes),
      })),
    })),
  };
}

/**
 * Total sum dpy has no meaning if missing metrics from current period are not
 * the same with the previous period.
 */
function missingMetricsInBothPeriodsAreMatching(panels) {
  return panels
    .map(panel => panel.sections[0].metrics[0])
    .every(
      metric =>
        isNullOrUndefined(metric.value) ===
        isNullOrUndefined(metric.prevYearValue)
    );
}

function computeChartAndSum(data, selectValue) {
  const chart = {
    adults: {
      labels: data.men.panels.map(panel => panel.panelName),
      series: [
        data.men.panels
          .map(panel => selectValue(panel.sections[0].metrics[0]))
          .map(value => value ?? 0),
        data.women.panels
          .map(panel => selectValue(panel.sections[0].metrics[0]))
          .map(value => value ?? 0),
      ],
    },
    children: {
      labels: data.boys.panels.map(panel => panel.panelName),
      series: [
        data.boys.panels
          .map(panel => selectValue(panel.sections[0].metrics[0]))
          .map(value => value ?? 0),
        data.girls.panels
          .map(panel => selectValue(panel.sections[0].metrics[0]))
          .map(value => value ?? 0),
      ],
    },
  };

  const menSum = chart.adults.series[0].reduce((acc, crt) => acc + crt, 0);
  const womenSum = chart.adults.series[1].reduce((acc, crt) => acc + crt, 0);
  const adultsTotalSum = menSum + womenSum;
  const boysSum = chart.children.series[0].reduce((acc, crt) => acc + crt, 0);
  const girlsSum = chart.children.series[1].reduce((acc, crt) => acc + crt, 0);
  const childrenTotalSum = boysSum + girlsSum;
  return {
    chart,
    menSum,
    womenSum,
    boysSum,
    girlsSum,
    adultsTotalSum,
    childrenTotalSum,
  };
  //return { chart, menSum, womenSum, totalSum };
}

function computeChartAndTotalsData(data, currency) {
  // There are 2 cases here:
  // 1. Data exists for both the current year and the previous year, but the
  //    value for each is 0. In this case total sum is 0 (correct) but
  //    percentage cannot be sensibly computed.
  // 2. Data does not exist. This method will not be called.

  const {
    chart,
    menSum,
    womenSum,
    boysSum,
    girlsSum,
    adultsTotalSum,
    childrenTotalSum,
  } = computeChartAndSum(data, metric => metric.value);

  const {
    menSum: prevMenSum,
    womenSum: prevWomenSum,
    boysSum: prevBoysSum,
    girlsSum: prevGirlsSum,
  } = computeChartAndSum(data, metric => metric.prevYearValue);

  let menSumDpy;
  let womenSumDpy;
  let boysSumDpy;
  let girlsSumDpy;

  if (missingMetricsInBothPeriodsAreMatching(data.men.panels)) {
    menSumDpy = computeDpy(menSum, prevMenSum, null, 'high');
  }
  if (missingMetricsInBothPeriodsAreMatching(data.women.panels)) {
    womenSumDpy = computeDpy(womenSum, prevWomenSum, null, 'high');
  }

  if (missingMetricsInBothPeriodsAreMatching(data.boys.panels)) {
    boysSumDpy = computeDpy(boysSum, prevBoysSum, null, 'high');
  }

  if (missingMetricsInBothPeriodsAreMatching(data.girls.panels)) {
    girlsSumDpy = computeDpy(girlsSum, prevGirlsSum, null, 'high');
  }
  const totals = {
    adults: {
      men: {
        formattedSum: formatMoney({ currency_code: currency, value: menSum }),
        formattedPercentage:
          adultsTotalSum === 0
            ? null
            : formatPercentage(menSum / adultsTotalSum, {
                maximumFractionDigits: 0,
              }),
        genderLabel: 'Men',
        dpy: menSumDpy,
      },
      women: {
        formattedSum: formatMoney({ currency_code: currency, value: womenSum }),
        formattedPercentage:
          adultsTotalSum === 0
            ? null
            : formatPercentage(womenSum / adultsTotalSum, {
                maximumFractionDigits: 0,
              }),
        genderLabel: 'Women',
        dpy: womenSumDpy,
      },
    },
    children: {
      boys: {
        formattedSum: formatMoney({ currency_code: currency, value: boysSum }),
        formattedPercentage:
          childrenTotalSum === 0
            ? null
            : formatPercentage(boysSum / childrenTotalSum, {
                maximumFractionDigits: 0,
              }),
        genderLabel: 'Boys',
        dpy: boysSumDpy,
      },
      girls: {
        formattedSum: formatMoney({ currency_code: currency, value: girlsSum }),
        formattedPercentage:
          childrenTotalSum === 0
            ? null
            : formatPercentage(girlsSum / childrenTotalSum, {
                maximumFractionDigits: 0,
              }),
        genderLabel: 'Girls',
        dpy: girlsSumDpy,
      },
    },
  };

  return {
    ...data,
    chart: childrenTotalSum === 0 && adultsTotalSum === 0 ? null : chart,
    totals,
  };
}

export function transformStocks(
  stockResponse,
  kpiMetaResponse,
  useUsdAmount,
  includeTaxes,
  periodId,
  useLLY
) {
  let result;

  const currency = useUsdAmount
    ? 'USD'
    : selectCurrencyFromStocksResponse(stockResponse);
  result = standardizeStocksResponse(
    stockResponse,
    kpiMetaResponse,
    periodId,
    useLLY
  );

  if (!result) {
    return null;
  }
  result = {
    men: convertToDisplayValues(result.men, currency, includeTaxes),
    women: convertToDisplayValues(result.women, currency, includeTaxes),
    boys: convertToDisplayValues(result.boys, currency, includeTaxes),
    girls: convertToDisplayValues(result.girls, currency, includeTaxes),
  };

  result = computeChartAndTotalsData(result, currency, includeTaxes);

  // TODO: (actual API) It's advised that the `asOf` be at the root of the response
  attachAndFormatTimeStamp(result, stockResponse.asOf);

  return result;
}
