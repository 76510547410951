const createChild = (key, value) => {
  if (!key) {
    return value;
  }
  return {
    [key]: value,
  };
};

/**
 * Performs an outer join on two arrays.
 *
 * @param {any[]} listA
 * @param {any[]} listB
 * @param {string} keyAName
 * @param {string} keyBName
 * @param {any => any} byFunc
 * @param {any => any} [byFuncB]
 * @returns {any[]}
 */
export const joinBy = (listA, listB, keyAName, keyBName, byFunc, byFuncB) => {
  const _byFuncB = byFuncB || byFunc;
  const map = new Map();
  for (let m of listA) {
    const entry = {
      ...createChild(keyAName, m),
    };
    map.set(byFunc(m), entry);
  }
  for (let m of listB) {
    let entry = map.get(_byFuncB(m));
    entry = entry || {};
    entry = {
      ...entry,
      ...createChild(keyBName, m),
    };
    map.set(_byFuncB(m), entry);
  }
  return Array.from(map.values());
};
