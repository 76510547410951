import { withStyles, Button } from '@material-ui/core';
import cx from 'clsx';
import Lottie from 'lottie-web/build/player/lottie_light';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Text1 } from '../../typography';

import animationData from './data.json';

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    position: 'fixed', // Disables scrolling on iOS.
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeIn,
    }),
  },
  entering: {
    '& $content': {
      opacity: 0,
    },
  },
  exiting: {
    transform: 'translateY(-25vh)',
    '& $curtain': {
      transform: 'translateY(-100%)',
    },
  },
  animation: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    position: 'absolute',
    top: `calc(${theme.spacing(4)}px + env(safe-area-inset-top))`,
    bottom: `calc(${theme.spacing(4)}px + env(safe-area-inset-bottom))`,
    left: `calc(${theme.spacing(4)}px + env(safe-area-inset-left))`,
    right: `calc(${theme.spacing(4)}px + env(safe-area-inset-right))`,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeOut,
    }),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  login: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    maxHeight: '100vw',
    flex: 1,
  },
  loginButton: {
    backgroundColor: theme.palette.secondary.main,
    paddingLeft: theme.spacing(5.5),
    paddingRight: theme.spacing(5.5),
    minHeight: 40,
  },
  noSplash: {
    '& $animation svg': {
      maxWidth: 480,
      maxHeight: 800,
    },
    '& $login': {
      maxHeight: 420,
    },
  },
  curtain: {
    background: theme.palette.common.white,
    height: '100vh',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeIn,
    }),
  },
});

const Login = ({ classes, onClickLogin, splash = true }) => {
  const enterAnimationContainer = useRef();
  const [enterAnimationComplete, setEnterAnimationComplete] = useState(!splash);
  const [exiting, setExiting] = useState(false);

  useLayoutEffect(() => {
    const animation = Lottie.loadAnimation({
      container: enterAnimationContainer.current,
      loop: false,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    });
    if (!splash) {
      animation.goToAndStop(animation.totalFrames - 1, true);
      return;
    }
    const onAnimationComplete = () => setEnterAnimationComplete(true);
    animation.addEventListener('complete', onAnimationComplete);
    return () => animation.removeEventListener('complete', onAnimationComplete);
  }, [enterAnimationContainer, splash]);

  useEffect(() => {
    const onPageShow = ({ persisted }) => {
      if (persisted) {
        setExiting(false);
      }
    };
    window.addEventListener('pageshow', onPageShow);
    return () => window.removeEventListener('pageshow', onPageShow);
  }, []);

  return (
    <div
      className={cx(
        classes.container,
        enterAnimationComplete || classes.entering,
        exiting && classes.exiting,
        splash || classes.noSplash
      )}
    >
      <div ref={enterAnimationContainer} className={classes.animation} />
      <div className={classes.content}>
        <div className={classes.login}>
          <Button
            onClick={() => {
              setExiting(true);
              setTimeout(
                () =>
                  onClickLogin({
                    cancelLogin: () => setExiting(false),
                  }),
                200
              );
            }}
            color="inherit"
            variant="outlined"
            className={classes.loginButton}
          >
            <Text1 bold caps>
              Log in
            </Text1>
          </Button>
        </div>
      </div>
      <div className={classes.curtain} />
    </div>
  );
};

Login.propTypes = {
  onClickLogin: PropTypes.func.isRequired,
  splash: PropTypes.bool,
};

export default withStyles(styles, { name: 'LriLogin' })(Login);
