import React from 'react';
import {
  CircularProgress,
  Snackbar,
  withStyles,
  Paper,
} from '@material-ui/core';

const styles = theme => ({
  snackbar: {
    bottom: theme.spacing(10),
  },
  snackbarContent: {
    backgroundColor: 'unset',
    justifyContent: 'center',
  },
  paper: {
    borderRadius: '50%',
    padding: theme.spacing(1),
  },
  progressBar: {
    verticalAlign: 'middle',
  },
});

const LoadingBar = ({ classes }) => {
  return (
    <Snackbar
      className={classes.snackbar}
      ContentProps={{
        elevation: 0,
        classes: { root: classes.snackbarContent },
      }}
      open={true}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message={
        <Paper className={classes.paper} elevation={6}>
          <CircularProgress
            className={classes.progressBar}
            color="secondary"
            disableShrink={true}
          />
        </Paper>
      }
    />
  );
};

export default withStyles(styles, { name: 'LriLoadingBar' })(LoadingBar);
