import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, CardContent, styled } from '@material-ui/core';

import Card from './Card';
import { Close } from '../Icons';

const useStyles = props => {
  return makeStyles(theme => ({
    container: {
      backgroundColor: 'transparent',
    },
    content: {
      minHeight: 48,
      boxSizing: 'border-box',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background[props.bgColor],
      width: '100%',
      height: '100%',
    },
  }));
};

const PlusIcon = styled(Close)(({ pluscolor, theme }) => ({
  fontSize: '1.2rem',
  padding: 6,
  borderRadius: '50%',
  color: theme.palette.text[pluscolor],
  transform: 'rotate(45deg) scaleX(-1)',
}));

const AddKPICard = ({
  pluscolor = 'secondary',
  bgColor = 'paper',
  onClick,
}) => {
  const classes = useStyles({ bgColor })();

  return (
    <Card onClick={onClick} className={classes.container}>
      <CardContent className={classes.content}>
        <PlusIcon pluscolor={pluscolor} />
      </CardContent>
    </Card>
  );
};

AddKPICard.propTypes = {
  onClick: PropTypes.func,
};

export default AddKPICard;
