import PropTypes from 'prop-types';
import React from 'react';

import ErrorPage from './ErrorPage';

const ErrorPageOrChildren = ({ errors, children, ...errorPageProps }) => {
  const someError = errors.some(err => Boolean(err));
  return someError ? <ErrorPage {...errorPageProps} /> : children;
};

ErrorPageOrChildren.propTypes = {
  ...ErrorPage.propTypes,
  errors: PropTypes.array.isRequired,
  children: PropTypes.node,
};

export default ErrorPageOrChildren;
