const errors = {
  access_denied: {
    title: 'Access denied',
    message:
      'You do not have access to Retail Insights. To request access, contact ' +
      'your line manager.',
  },
  generic: {
    title: 'Login error',
    message:
      'There was an unexpected problem trying to log you in. If this problem ' +
      'persists, contact the Global Service Desk.',
  },
  redirect_failed: {
    title: 'Connection error',
    message:
      'Your device may be offline or the login server may be experiencing ' +
      'problems. If your device is online and this problem persists, contact ' +
      'the Global Service Desk.',
  },
  temporarily_unavailable: {
    title: 'Temporarily unavailable',
    message:
      'The login server is currently unable to handle your request. Try ' +
      'again shortly. If this problem persists, contact the Global Service ' +
      'Desk.',
  },
};

/**
 * @param {string} errorType
 *   One of: access_denied, generic, redirect_failed or temporarily_unavailable.
 *   Any other value will result in `undefined`.
 * @returns {{ title: string, message: React.ReactNode }|undefined}
 */
export const errorMessage = errorType => errors[errorType];
