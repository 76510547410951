import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { CollapseButtonIcon } from '../Icons';

const StyledButton = withStyles(t => ({
  root: {
    textTransform: 'capitalize',
  },
  iconSizeSmall: {
    '&>:first-child': {
      fontSize: 14,
    },
    fontSize: 14,
    opacity: 0.3,
  },
}))(Button);

const BarButton = props => (
  <StyledButton size="small" endIcon={<CollapseButtonIcon />} {...props} />
);

export default BarButton;
