import { useRef, useEffect } from 'react';
import { useRetryPeriod } from './useRetryPeriod';

export const useHandleNotGeneratedPeriod = ({ response, status }) => {
  const notFoundCountRef = useRef(0);
  const { setRetryPeriod } = useRetryPeriod();

  useEffect(() => {
    if (status === 0) {
      notFoundCountRef.current = 0;
    }

    if (response.error?.response?.status === 404) {
      notFoundCountRef.current += 1;
    }

    if (response.data) {
      notFoundCountRef.current = 0;
    }

    // Making a huge assumption that simply a 404 error is mainly because prior day is not yet generated
    // therefore triggering another flow for previous prior day.
    // TODO have the server specify the problem and available alternatives
    if (notFoundCountRef.current === 1) {
      setRetryPeriod(true);
    }

    // When 'sampling' for more than a second time the response data and
    // and the response is still not found, assume it is not the period selection fault
    if (notFoundCountRef.current > 1) {
      notFoundCountRef.current = 0;
    }
  }, [setRetryPeriod, response, status]);
};
