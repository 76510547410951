import { createTheme } from '@material-ui/core/styles';

import * as colors from './colors';
import { typography } from './typography';

// Need a theme object to use the `spacing` function.
const defaultTheme = createTheme();

const theme = createTheme({
  typography,
  palette: {
    primary: { main: colors.brandE, contrastText: colors.light1 },
    secondary: { main: colors.brandC, contrastText: colors.light1 },
    tertiary: defaultTheme.palette.augmentColor({
      main: colors.brandD,
      contrastText: colors.light1,
    }),
    divider: colors.dark5,
    headerDivider: colors.paleBlue,
    headerCollapse: colors.lightBlue,
    good: defaultTheme.palette.augmentColor({
      main: colors.green,
      contrastText: colors.light1,
    }),
    bad: defaultTheme.palette.augmentColor({
      main: colors.red,
      contrastText: colors.light1,
    }),
    neutral: defaultTheme.palette.augmentColor({
      main: colors.dark3,
      contrastText: colors.light1,
    }),
    background: {
      paper: colors.light1,
      panel: colors.dark5,
      default: colors.light1,
      action: colors.light4,
      primary: colors.brandE,
    },
    action: {
      active: colors.dark3,
    },
    text: {
      primary: colors.dark1,
      secondary: colors.dark2,
      tertiary: colors.light1,
    },
  },
  overrides: {
    MuiCardContent: {
      root: {
        '&:last-child': null,
      },
    },
    MuiBottomNavigation: {
      root: {
        backgroundColor: colors.veryLightGrey,
      },
    },
    MuiBottomNavigationAction: {
      root: {
        color: colors.dark3,
      },
    },
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: '3.2rem',
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none !important',
      },
    },
    MuiRadio: {
      root: {
        color: colors.dark3,
        padding: 0,
      },
    },
    MuiSwitch: {
      root: {
        width: 50,
        height: 30,
        padding: 0,
        margin: defaultTheme.spacing(1),
      },
      switchBase: {
        color: defaultTheme.palette.common.white,
        padding: 2,
        '&$checked': {
          transform: 'translateX(20px)',
          '& + $track': {
            backgroundColor: colors.vibrantGreen,
            opacity: 1,
            border: 'none',
          },
        },
        '&$focusVisible $thumb': {
          border: `6px solid ${colors.dark3}`,
        },
        '& .MuiSvgIcon-root': {
          background: defaultTheme.palette.common.white,
          borderRadius: '50%',
          boxSizing: 'border-box',
          height: 26,
          width: 26,
          padding: 4,
        },
        '&$focusVisible .MuiSvgIcon-root': {
          border: `4px solid ${colors.dark3}`,
          padding: 0,
        },
      },
      thumb: {
        boxSizing: 'border-box',
        width: 26,
        height: 26,
        boxShadow: 'none',
      },
      track: {
        boxSizing: 'border-box',
        borderRadius: 30 / 2,
        border: 'none',
        backgroundColor: colors.light3,
        opacity: 1,
      },
      checked: {},
      focusVisible: {},
    },
  },
});

export default theme;
