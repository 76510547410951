import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Select, SelectDrawer } from '../../components/Select';
import { isNullOrUndefined } from '../../util/object';
import { usePreferencesManager } from '../user-preferences';
import defaultSort from '../utils/defaultSort';

const sortOptions = [
  {
    id: 'dpy_asc',
    label: 'Underperforming first',
    func: (a, b) => {
      if (isNullOrUndefined(a.dpy)) {
        return 1;
      }
      if (isNullOrUndefined(b.dpy)) {
        return -1;
      }
      return a.dpy.delta - b.dpy.delta;
    },
  },
  {
    id: 'dpy_desc',
    label: 'Overperforming first',
    func: (a, b) => {
      if (isNullOrUndefined(a.dpy)) {
        return 1;
      }
      if (isNullOrUndefined(b.dpy)) {
        return -1;
      }
      return b.dpy.delta - a.dpy.delta;
    },
  },
  {
    id: 'name',
    label: 'Name',
    func: (a, b) => a.label.localeCompare(b.label),
  },
  {
    id: 'default',
    label: 'Default',
    func: defaultSort,
  },
];

const SortShell = ({ children, onSort, sortOptionsId }) => {
  let finalSortOptions = sortOptions;
  if (!isNullOrUndefined(sortOptionsId)) {
    finalSortOptions = sortOptions.filter(x => sortOptionsId.includes(x.id));
  }
  const pm = usePreferencesManager();

  const [drawer, setDrawer] = useState(false);
  const [selected, setSelected] = useState(
    finalSortOptions.find(x => x.id === pm.get('sort_by')) || sortOptions[3]
  );

  const toggleSelect = () => {
    setDrawer(true);
  };

  const handleSelect = item => {
    setSelected(item);
    pm.set('sort_by', item.id);
    if (onSort) {
      onSort({
        id: item.id,
        label: item.label,
      });
    }
    setDrawer(false);
  };

  return (
    <>
      {children(selected, toggleSelect)}
      <SelectDrawer
        open={drawer}
        title="Sort By"
        onClose={() => setDrawer(false)}
      >
        <Select
          items={finalSortOptions}
          selected={selected}
          onSelect={handleSelect}
        />
      </SelectDrawer>
    </>
  );
};

SortShell.propTypes = {
  onSort: PropTypes.func,
  sortOptionsId: PropTypes.array,
};

export default SortShell;
