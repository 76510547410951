import { isNullOrUndefined } from '../util/object';
import { getCustomMap } from './config';

const createTag = id => {
  const tag = document.createElement('script');
  tag.async = true;
  tag.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  return tag;
};

const findInsertionPoint = () => {
  const charsetTag = document.querySelector('meta[charset]');
  if (charsetTag) {
    return [charsetTag, 'afterend'];
  }
  return [document.head, 'afterbegin'];
};

export function gtag() {
  window.dataLayer = window.dataLayer || [];
  // It's important to use `arguments` here per the GA docs. An array doesn't
  // work the same way (it ends up creating two script tags).
  window.dataLayer.push(arguments);
}
export function GTAG_EVENT({ view, category = '', label = '' }) {
  gtag('event', view, {
    event_category: category,
    event_label: label,
  });
}
export const init = config => {
  const id = config.GA_MEASUREMENT_ID;
  if (isNullOrUndefined(id)) {
    return;
  }
  if (typeof id !== 'string' || id.length < 1) {
    throw new Error(
      `Invalid measurement ID '${id}' - must be a non-empty string.`
    );
  }
  gtag('js', new Date());
  gtag('config', id, {
    // Page view events must be sent manually from components.
    send_page_view: false,
    site_speed_sample_rate: 100,
    transport_type: 'beacon',
    custom_map: getCustomMap(config),
  });
  if (process.env.REACT_APP_VERSION) {
    gtag('set', { version: process.env.REACT_APP_VERSION });
  }
  const tag = createTag(id);
  const [target, position] = findInsertionPoint();
  target.insertAdjacentElement(position, tag);
};
