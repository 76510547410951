export function selectCurrencyFromMetricsResponse(response) {
  return Object.values(Object.values(response.metrics)[0]).find(
    x => x?.value?.currency ?? false
  )?.value.currency;
}

export function selectCurrencyFromStocksResponse(response) {
  return Object.values(
    Object.values(Object.values(response.sales_and_inventory.total_sales)[0])[0]
  )
    .map(o => Object.values(o))
    .flat()
    .find(x => x?.value?.currency ?? false).value.currency;
}

export function selectCurrencyFromProductsResponse(response) {
  return Object.values(
    Object.values(Object.values(Object.values(response)[0])[0])[0]
  )
    .flat()
    .map(x => Object.values(x))
    .flat()
    .find(x => x?.value?.currency)?.value?.currency;
}

export function chooseCurrencyFromMetricsResponses(
  responseA,
  responseB,
  useUsdAmount
) {
  let currency;
  if (useUsdAmount) {
    currency = 'USD';
  } else {
    const currencyA = selectCurrencyFromMetricsResponse(responseA);
    const currencyB = selectCurrencyFromMetricsResponse(responseB);
    currency = currencyA === currencyB ? currencyA : 'USD';
  }
  return currency;
}
