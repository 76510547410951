import { shallowEqual } from 'fast-equals';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { usePrevious } from '../hooks';
import { gtag } from './init';

/**
 *
 * @example
 *   useScreenView('kpis', )
 */
export const usePageView = params => {
  const location = useLocation();
  const path = process.env.PUBLIC_URL + location.pathname + location.search;
  const prevPath = usePrevious(path);
  const prevParams = usePrevious(params);
  useEffect(() => {
    if (path !== prevPath) {
      // GA docs recommend setting page_path for the session instead of
      // attaching it to a single event.
      gtag('set', 'page_path', path);
    }
    // It's awkward to 'shallow compare' an object in the hook's dependency
    // array so instead the effect is run every time and the compare happens
    // inside it.

    if (path !== prevPath || !shallowEqual(params, prevParams)) {
      const performanceObj = window.performance.timing;
      const pageLoadTime =
        performanceObj.loadEventEnd - performanceObj.navigationStart;
      gtag('event', 'pageview', params);
      gtag('event', 'timing_complete', {
        name: 'load',
        value: pageLoadTime,
        event_category: 'Page Load Timings',
        event_label: location.pathname,
      });
    }
  });
};

export const PageView = ({ children, params }) => {
  usePageView(params);
  return children;
};
