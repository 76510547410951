import cx from 'clsx';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  withStyles,
} from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import { Text2 } from '../../typography';

const styles = theme => ({
  root: {},
  checked: {
    fontWeight: 'bold',
  },
  checkIcon: {
    color: theme.palette.primary.main,
  },
});

const CheckListItem = ({
  checked = false,
  children,
  classes,
  className,
  ...props
}) => (
  <ListItem
    className={cx(classes.root, checked && classes.checked, className)}
    dense
    button
    {...props}
  >
    <ListItemText>
      <Text2 gray bold={checked}>
        {children}
      </Text2>
    </ListItemText>
    {checked && (
      <ListItemSecondaryAction>
        <CheckIcon className={classes.checkIcon} />
      </ListItemSecondaryAction>
    )}
  </ListItem>
);

CheckListItem.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
};

export default withStyles(styles, { name: 'LriCheckListItem' })(CheckListItem);
