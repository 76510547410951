import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const CardGrid = ({
  children,
  justify = 'center',
  itemXs = 6,
  itemSm = 6,
  itemMd = 6,
  itemLg = 6,
  ...props
}) => {
  return (
    <Grid
      justifyContent={justify}
      container
      spacing={1}
      {...props}
      alignItems="flex-start"
    >
      {React.Children.map(
        children,
        (c, i) =>
          c !== null && (
            <Grid
              container
              justifyContent="center"
              key={i}
              item
              xs={itemXs}
              sm={itemSm}
              md={itemMd}
              lg={itemLg}
            >
              {c}
            </Grid>
          )
      )}
    </Grid>
  );
};

CardGrid.propTypes = {
  ...Grid.propTypes,
  justify: PropTypes.string,
  itemXs: PropTypes.number,
  itemSm: PropTypes.number,
  itemMd: PropTypes.number,
  itemLg: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default CardGrid;
