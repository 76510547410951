import React from 'react';
import {
  ListItem,
  Radio,
  styled,
  withStyles,
  Divider,
} from '@material-ui/core';
import { CollapseButtonIcon } from '../Icons';

const selectItemStyles = theme => ({
  root: {
    justifyContent: 'space-between',
    marginTop: -1,
    minHeight: theme.spacing(7),
  },
  gutters: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
});

const StyledDivider = withStyles(theme => ({
  root: {
    width: `calc(100% - ${theme.spacing(3)}px)`,
    position: 'absolute',
    bottom: 0,
  },
}))(Divider);

export const SelectItem = withStyles(selectItemStyles, {
  name: 'LriSelectItem',
})(({ onClick, iconComponent, children, iconOnLeft = false, ...props }) => (
  <ListItem {...props} button onClick={onClick}>
    {iconOnLeft && iconComponent}
    {children}
    {!iconOnLeft && iconComponent}
    <StyledDivider />
  </ListItem>
));

export const RadioSelectItem = ({ checked, ...props }) => {
  return (
    <SelectItem
      {...props}
      iconComponent={<Radio color="primary" checked={checked} />}
    />
  );
};

const LeftArrowIcon = styled(CollapseButtonIcon)(({ theme }) => ({
  transform: 'rotate(90deg) scaleX(-1)',
  color: theme.palette.action.active,
  marginRight: theme.spacing(1),
}));

const RightArrowIcon = styled(CollapseButtonIcon)(({ theme }) => ({
  transform: 'rotate(-90deg) scaleX(-1)',
  color: theme.palette.action.active,
  marginRight: theme.spacing(0.5),
  fontSize: '1.4rem',
}));

export const RightArrowSelectItem = props => {
  return <SelectItem {...props} iconComponent={<RightArrowIcon />} />;
};

const leftArrowSelectItemStyles = theme => ({
  root: {
    justifyContent: 'start',
    marginTop: theme.spacing(1),
    borderBottom: 'none',
  },
});

export const LeftArrowSelectItem = withStyles(
  leftArrowSelectItemStyles
)(props => (
  <SelectItem {...props} iconComponent={<LeftArrowIcon />} iconOnLeft={true} />
));
