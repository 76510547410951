/**
 * @param {ServiceWorkerRegistration} registration
 * @type {Promise<ServiceWorkerRegistration>}
 *   The same registration originally given to the function.
 */
export const updateAvailable = registration =>
  new Promise(async resolve => {
    const notify = () => {
      console.log(
        'A service worker has been installed and is waiting to be activated.'
      );
      resolve(registration);
    };

    const onInstalled = () => {
      const installingWorker = registration.installing;
      installingWorker.addEventListener('statechange', () => {
        if (installingWorker.state === 'installed') {
          notify();
        }
      });
    };

    if (registration.installing) {
      onInstalled();
    }
    if (registration.waiting) {
      notify();
    }
    registration.addEventListener('updatefound', onInstalled);
  });
