import * as Sentry from '@sentry/browser';

import { isNullOrUndefined } from './util/object';

/**
 * @param {Error|null|undefined} error
 * @param {object} [extraInfo]
 */
export const reportError = (error, extraInfo) => {
  if (isNullOrUndefined(error)) {
    console.trace();
    return;
  }
  console.error('Reported', error, extraInfo);
  Sentry.withScope(scope => {
    if (!isNullOrUndefined(extraInfo)) {
      scope.setExtras(extraInfo);
    }
    Sentry.captureException(error);
  });
};
