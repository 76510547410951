import { createContext, useContext } from 'react';

import { noop, debounceAsync } from '../util/function';
import { updateAvailable } from './update';

export const UpdateManager = createContext({ checkForUpdates: noop });
export const useUpdateManager = () => useContext(UpdateManager);

export function createUpdateManager({ checkIntervalMs }) {
  // Because updates are activated as soon as they are installed and the page
  // reloaded automatically, the `updateManager` does not need a complicated
  // signature. If we switch to requiring user interaction before updating then
  // we can extend it with `updateAvailable` and `activateLatest` properties.
  const updateManager = { checkForUpdates: noop };

  if ('serviceWorker' in navigator) {
    let timer;
    const refreshTimer = () => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(updateManager.checkForUpdates, checkIntervalMs);
    };

    navigator.serviceWorker.ready.then(registration => {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        window.location.reload();
      });
      updateAvailable(registration).then(() => {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      });
      updateManager.checkForUpdates = debounceAsync(() => {
        refreshTimer();
        console.log('Checking for updates to service worker');
        return registration.update();
      });
      refreshTimer();
    });
  }

  return updateManager;
}
