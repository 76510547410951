import React from 'react';
import * as colors from '../theme/colors';
import { Tab, Tabs, withStyles, Paper } from '@material-ui/core';

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minHeight: 'unset',
    padding: '1px 0',
    ...theme.typography.body1,
  },
  textColorInherit: {
    opacity: props => (props.darkmode === 'true' ? 1 : 0.7),
  },
}))(Tab);

const ButtonTab = props => <StyledTab disableRipple {...props} />;
ButtonTab.propTypes = { ...Tab.propTypes };

const StyledPaper = withStyles(theme => ({
  root: {
    flex: 1,
    margin: 2,
    backgroundColor: props =>
      props.darkmode === 'true'
        ? colors.light4
        : theme.palette.background.paper,
  },
}))(Paper);

const StyledTabs = withStyles(theme => ({
  root: {
    backgroundColor: props =>
      props.darkmode === 'true'
        ? theme.palette.tertiary.main
        : theme.palette.background.panel,
    color: props =>
      props.darkmode === 'true'
        ? theme.palette.text.tertiary
        : theme.palette.text.primary,
    borderRadius: 6,
    minHeight: 'unset',
  },
  flexContainer: {
    position: 'relative',
    zIndex: 1,
  },
  indicator: {
    background: 'none',
    display: 'flex',
    height: '100%',
  },
}))(Tabs);

const ButtonTabs = ({ variant = 'fullWidth', ...props }) => (
  <StyledTabs
    variant={variant}
    {...props}
    TabIndicatorProps={{
      children: <StyledPaper elevation={1} darkmode={props.darkmode} />,
    }}
  />
);
// eslint-disable-next-line react/forbid-foreign-prop-types
ButtonTabs.propTypes = { ...Tabs.propTypes };

export { ButtonTabs, ButtonTab };
