import React from 'react';
import { withStyles } from '@material-ui/core';
import { styles as toolbarStyles } from '@material-ui/core/Toolbar/Toolbar';

const ToolbarSpacer = ({ classes, component: Component = 'div', variant }) => (
  <Component
    className={variant === 'dense' ? classes.dense : classes.regular}
  />
);

export default withStyles(toolbarStyles, { name: 'LriToolbarSpacer' })(
  ToolbarSpacer
);
