import * as Sentry from '@sentry/browser';

import { isNullOrUndefined } from '../util/object';

/**
 * Initialise Sentry from the application config.
 *
 * @param {object} config
 * @param {string} [config.SENTRY_DSN]
 * @param {string} [config.SENTRY_ENV]
 */
export const init = config => {
  const { SENTRY_DSN: dsn, SENTRY_ENV: environment } = config;
  if (isNullOrUndefined(dsn)) {
    return;
  }

  Sentry.init({
    dsn,
    environment,
    release: process.env.REACT_APP_SENTRY_RELEASE_VERSION,
    debug: process.env.NODE_ENV === 'development',
  });
  Sentry.setExtra('config', config);
};

/**
 * Test if error reporting to Sentry is enabled.
 *
 * This will return false, for example, when SENTRY_DSN is not set in config.
 */
export const isEnabled = () => Boolean(Sentry.getCurrentHub().getClient());
