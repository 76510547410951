import { requirable } from './isRequired';
import { delimit as d } from './testUtils';

const entryKeys = ['main', 'light', 'dark', 'contrastText'];

const isColorInPalette = (color, theme) => {
  const entry = theme.palette?.[color];
  return entry && entryKeys.every(key => typeof entry[key] === 'string');
};

/**
 *
 * @param {string} themePropName
 * @returns {check & { isRequired: check }}
 */
const paletteColor = themePropName =>
  requirable((props, propName, componentName) => {
    const theme = props[themePropName];
    const color = props[propName];

    if (!theme) {
      return new Error(
        `Prop ${d(propName)} was given to ${d(componentName)} without prop ${d(
          themePropName
        )} to provide a theme palette.`
      );
    }

    if (typeof color !== 'string') {
      return new Error(
        `Prop ${d(propName)} given to ${d(componentName)} must be a string.`
      );
    }

    if (!isColorInPalette(color, theme)) {
      return new Error(
        `Invalid prop ${d(propName)} given to ${d(
          componentName
        )}. ${JSON.stringify(color)} is not a valid color in the theme palette.`
      );
    }
  });

export const themePaletteColor = paletteColor('theme');
