import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Note } from '../typography';

const styles = theme => ({
  root: {
    background: 'rgba(0, 0, 0, 0.3)',
    color: theme.palette.common.white,
    padding: theme.spacing(1, 4),
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 2,
    textAlign: 'center',
  },
});

const TimeStampPanel = ({ formattedValue, classes, text, style }) => {
  return (
    <Note bold caps classes={classes} style={style}>
      {text
        ? text
        : `You are seeing data from ${formattedValue} until new data is available.`}
    </Note>
  );
};

TimeStampPanel.propTypes = {
  formattedValue: PropTypes.string.isRequired,
  classes: PropTypes.object,
  styles: PropTypes.object,
  text: PropTypes.string,
};

export default withStyles(styles)(TimeStampPanel);
