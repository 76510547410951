import { filterValues } from './object';

/**
 * Convert an object to a URL query string.
 *
 * `undefined` and `null` values are not included in the returned string.
 *
 * @param {object} searchParams
 * @returns {string}
 */
export const stringify = searchParams => {
  if (!searchParams) {
    return '';
  }
  const filtered = filterValues(
    searchParams,
    x => x !== undefined && x !== null
  );
  return String(new URLSearchParams(filtered));
};

/**
 * Converts a URL query string to an object
 *
 * @param {string} queryString
 * @returns {object}
 */
export const parse = queryString => {
  const params = new URLSearchParams(queryString);
  return Object.fromEntries(params);
};
