import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Select, SelectDrawer } from '../components/Select';
import SecondaryBar from '../components/AppBar/SecondaryBar';

const SalesUnitsSwitchList = ({ selected, onSelect, salesUnitsList }) => {
    const [drawer, setDrawer] = useState(false);

    const toggleSelect = () => {
        setDrawer(true);
    };

    const onClose = () => { };

    const handleSelect = item => {
        onSelect(item);
        setDrawer(false);
    };

    return (
        <>
            <SelectDrawer
                open={drawer}
                title={'Performance Metrics'}
                onClose={() => {
                    onClose();
                    setDrawer(false);
                }}
            >
                <Select
                    items={salesUnitsList}
                    selected={selected}
                    onSelect={handleSelect}
                />
            </SelectDrawer>
            <SecondaryBar text={selected.label} onClick={toggleSelect} />
        </>
    );
};

SalesUnitsSwitchList.propTypes = {
    selected: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
};

const SalesUnitsSwitch = React.memo(
    SalesUnitsSwitchList,
    (prevProps, nextProps) => prevProps.selected === nextProps.selected
);

export default SalesUnitsSwitch;