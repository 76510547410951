import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';

import * as CustomPropTypes from '../propTypes';

const styles = theme => {
  const fromPalette = (fallback, map = x => x.main) => ({ color }) =>
    theme.palette[color] ? map(theme.palette[color]) : fallback;
  const defaultBackground = theme.palette.divider;
  const defaultForeground = theme.palette.text.secondary;

  return {
    root: {
      alignItems: 'center',
      backgroundColor: fromPalette(defaultBackground),
      border: 'none',
      borderRadius: ({ size }) => (size === 'small' ? 9 : 12),
      boxSizing: 'border-box',
      color: fromPalette(defaultForeground, x => x.contrastText),
      display: 'inline-flex',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 'bold',
      letterSpacing: '0.04em',
      height: ({ size }) => (size === 'small' ? 18 : 24),
      padding: theme.spacing(0, 1),
      justifyContent: 'center',
      whiteSpace: 'nowrap',
    },
  };
};

const Pill = React.forwardRef(
  (
    {
      children,
      classes,
      color,
      className = '',
      component: Component = 'div',
      theme,
      size = 'medium',
      ...other
    },
    ref
  ) => (
    <Component ref={ref} className={cx(className, classes.root)} {...other}>
      {children}
    </Component>
  )
);
Pill.displayName = 'Pill';

Pill.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  color: CustomPropTypes.themePaletteColor,
  component: PropTypes.elementType,
  theme: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
};

export default withTheme(withStyles(styles, { name: 'LriPill' })(Pill));
