import React from 'react';
import { makeStyles, CardContent } from '@material-ui/core';

import Card from './Card';
import Pill from '../Pill';
import { Heading2, Text3 } from '../../typography';
import PerformancePill from '../PerformancePill';

const useStyles = makeStyles(theme => ({
  content: {
    boxSizing: 'border-box',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(3),
    '&:last-child': {
      paddingBottom: theme.spacing(3),
    },
  },
  bottomLabel: {
    marginBottom: theme.spacing(1.5),
    color: theme.palette.primary.contrastText,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.primary.contrastText}`,
  },
  pill: {
    margin: theme.spacing(0.5),
  },
}));

const SalesCard = ({
  color = 'default',
  topLabel,
  middleLabel,
  bottomLabel,
  delta,
  useLLY,
}) => {
  const classes = useStyles();

  return (
    <>
      <Card color={color}>
        <CardContent className={classes.content}>
          <Pill className={classes.bottomLabel}>
            <Text3>{bottomLabel}</Text3>
          </Pill>
          <Heading2 align="center" component="div">
            {topLabel}
          </Heading2>
          <Heading2 align="center" component="div" gray>
            {middleLabel}
          </Heading2>
        </CardContent>
      </Card>
      {delta && (
        <PerformancePill
          className={classes.pill}
          delta={delta}
          label={useLLY ? 'vs PPY' : 'vs PY'}
        />
      )}
      {!delta && <Pill className={classes.pill}>N/A</Pill>}
    </>
  );
};

export default SalesCard;
