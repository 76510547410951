import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Text3 } from '../typography';

const styles = theme => ({
  root: {
    borderRadius: 6,
    background: theme.palette.headerDivider,
    margin: theme.spacing(1, 0),
    display: 'flex',
    minHeight: 29,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const HeaderDivider = ({ classes, title }) => (
  <Text3 bold className={classes.root} align="center">
    {title}
  </Text3>
);

HeaderDivider.propTypes = {
  title: PropTypes.string.isRequired,
};

export default withStyles(styles, { name: 'LriHeaderDivider' })(HeaderDivider);
