import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { mapValues } from '../util/object';

const ConfigCtx = createContext();
ConfigCtx.displayName = 'Config';

/**
 * Provides configuration to nested components.
 *
 * Nested components can consume configuration with `useConfig`.
 */
export const ConfigProvider = ({ children, config }) => (
  <ConfigCtx.Provider value={config}>{children}</ConfigCtx.Provider>
);

ConfigProvider.propTypes = {
  children: PropTypes.node,
  config: PropTypes.objectOf(PropTypes.string),
};

/**
 * Returns configuration from the closest `ConfigProvider` ancestor component.
 *
 * @param {Object} [configMapping]
 *   An object describing which variables to extract from the configuration. The
 *   keys are arbitrary and the values are keys in the configuration.
 * @returns {Object}
 *   If `configMapping` is not specified the whole configuration is returned. If
 *   a mapping is specified the returned object will have the same shape as
 *   `configMapping` with any requested values that are missing being
 *   `undefined`.
 *
 * @example
 * <ConfigProvider config={{ LOG_LEVEL: 'warning', SHOW_TOOLBAR: true }}>
 *   {() => {
 *     const plain = useConfig()
 *     // { LOG_LEVEL: 'warning', SHOW_TOOLBAR: true }
 *
 *     const extract = useConfig({ log: 'LOG_LEVEL', email: 'SEND_EMAIL' })
 *     // { log: 'warning', email: undefined }
 *   }}
 * </ConfigProvider>
 */
export const useConfig = configMapping => {
  const config = useContext(ConfigCtx);
  if (config === undefined) {
    throw new ConfigError(
      'An attempt was made to useConfig but no configurations are available. ' +
        'Do you need to define a ConfigProvider?'
    );
  }
  return configMapping === undefined
    ? config
    : mapValues(configMapping, x => config[x]);
};

class ConfigError extends Error {}
