import { withStyles } from '@material-ui/core';
import cx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Arrow } from './Icons';

const styles = theme => {
  return {
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.action.active,
      border: 'none',
      boxSizing: 'border-box',
      height: 24,
      width: 24,
      fontSize: '1.4rem',
    },
    arrow: {},
    arrowLeft: {},
    arrowRight: {
      transform: 'scaleX(-1)',
    },
    equal: {
      fontSize: '140%',
    },
  };
};

const ComparisonIcon = ({
  children,
  classes,
  className,
  color,
  comparison,
  component: Component = 'div',
  ...other
}) => {
  let content;
  if (comparison === 0) {
    content = <span className={classes.equal}>=</span>;
  } else if (typeof comparison === 'number') {
    content = (
      <Arrow
        fontSize="inherit"
        className={cx(
          classes.arrow,
          comparison < 0 ? classes.arrowLeft : classes.arrowRight
        )}
      />
    );
  }

  return (
    <Component className={cx(className, classes.root)} {...other}>
      {content}
    </Component>
  );
};

ComparisonIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.elementType,
  comparison: PropTypes.number,
};

export default withStyles(styles, { name: 'LriComparisonIcon' })(
  ComparisonIcon
);
