/**
 * Color names and values from design's Library page
 */
export const brandA = '#2128ED';
export const brandE = '#141ad0';
export const brandC = '#19C3ED';
export const brandD = '#1115AF';

export const paleBlue = 'rgba(182, 207, 215, 0.3)';
export const vibrantGreen = '#68D800';
export const lightBlue = '#B6CFD7';

export const green = '#1EBF4B';
export const red = '#E42121';
export const veryLightGrey = '#F8F8F7';

export const light1 = '#FFF';
export const light2 = 'rgba(255, 255, 255, 0.65)';
export const light3 = 'rgba(255, 255, 255, 0.3)';
export const light4 = 'rgba(255, 255, 255, 0.16)';
export const light5 = 'rgba(255, 255, 255, 0.04)';

export const dark1 = '#000';
export const dark2 = 'rgba(0, 0, 0, 0.65)';
export const dark3 = 'rgba(0, 0, 0, 0.3)';
export const dark4 = 'rgba(0, 0, 0, 0.16)';
export const dark5 = 'rgba(0, 0, 0, 0.04)';
