const getCachedAPIValues = () => {
  return JSON.parse(localStorage.getItem('cached_api_values') || '{}');
};
const cacheAPIValues = value => {
  localStorage.setItem('cached_api_values', JSON.stringify(value));
};
export const setLocalStorageApiCache = (key, value) => {
  let cachedValue = getCachedAPIValues();
  cachedValue = {
    ...cachedValue,
    [key]: value,
  };
  cacheAPIValues(cachedValue);
};
export const getLocalStorageApiCacheValue = key => {
  let cachedValue = getCachedAPIValues();
  return JSON.parse(cachedValue[key] || '{}');
};
export const deleteLocalStorageApiCacheKey = key => {
  let cachedValue = getCachedAPIValues();
  delete cachedValue[key];
  cacheAPIValues(cachedValue);
};
export const clearLocalStorageApiCache = () => {
  localStorage.removeItem('cached_api_values');
};
