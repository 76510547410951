import React, { useState } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@mui/material';
import dayjs from 'dayjs';

const useStyles = makeStyles(theme => ({
  datePickerContainer: {
    '& .MuiStack-root .css-': {
      display: 'flex',
      flexDirection: 'row !important',
    },
  },

  datePicker: {
    '& .MuiSvgIcon-root': {
      fontSize: '2.5rem',
    },
    '& .MuiFormLabel-root': {
      fontSize: '1.5rem',
    },
    '& .MuiStack-root > div.MuiTextField-root': {
      minWidth: '50px !important',
    },
  },
  submitButton: {
    marginTop: '5px !important',
    marginLeft: '5px !important',
  },
}));

const DateRangePicker = ({ onDateRangeSelect, closeDrawer, id, className }) => {
  const classes = useStyles();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);

  const handleStartDateChange = date => {
    setStartDate(date);
    setEndDate(null);
    setOpenEndDatePicker(true);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
    setOpenEndDatePicker(false);
  };

  const handleSubmit = () => {
    if (onDateRangeSelect) {
      onDateRangeSelect(startDate, endDate);
    }
    closeDrawer();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={['DatePicker']}
        className={`${classes.datePickerContainer} ${className || ''}`}
        id={id}
        sx={{
          flexDirection: 'row !important',
          gap: '4px',
        }}
      >
        <DesktopDatePicker
          disableFuture
          label="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          minDate={dayjs().subtract(13, 'month')}
          renderInput={params => <TextField {...params} />}
          className={classes.datePicker}
          sx={{
            width: '50%',
            marginLeft: '5px !important',
            minWidth: '50px !important',
          }}
        />
        <DesktopDatePicker
          disableFuture
          label="End Date"
          value={endDate}
          minDate={startDate ? startDate : null}
          onChange={handleEndDateChange}
          open={openEndDatePicker}
          onOpen={() => setOpenEndDatePicker(true)}
          onClose={() => setOpenEndDatePicker(false)}
          renderInput={params => <TextField {...params} />}
          className={classes.datePicker}
          sx={{
            width: '50%',
            marginTop: '0 !important',
            marginRight: '5px !important',
            minWidth: '50px !important',
          }}
          disabled={!startDate}
        />
      </DemoContainer>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        className={classes.submitButton}
        disabled={!startDate || !endDate}
      >
        Submit
      </Button>
    </LocalizationProvider>
  );
};
export default DateRangePicker;
