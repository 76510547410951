import { checkPropTypes as ogCheckPropTypes } from 'prop-types';

/**
 * Delimit an identifier for use in a PropType error message.
 *
 * It's convention to use backticks around identifer names but those are tricky
 * to interpolate into template strings.
 *
 * @param {string} str
 * @returns {string}
 */
export const delimit = str => '`' + str + '`';

/**
 * Convenience function for calling PropTypes.checkPropTypes in tests.
 *
 * 1) It only requires 2 parameters instead of 4.
 * 2) It ensures that error messages are always unique to prevent prop-types
 *    from surpressing duplicates, enabling tests to check that error messages
 *    are actually logged.
 *
 * @param {object} typeSpecs Map of name to a ReactPropType
 * @param {object} props Runtime values that need to be type-checked
 */
export const checkPropTypes = (typeSpecs, props) =>
  ogCheckPropTypes(typeSpecs, props, 'prop', 'Test_' + ++checkPropTypeCalls);
let checkPropTypeCalls = 0;
