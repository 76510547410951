import { IconButton, withStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import ErrorMessage from './ErrorMessage';

const styles = theme => ({
  container: {
    background: theme.palette.background.default,
    position: 'fixed',
    bottom: 0,
    zIndex: theme.zIndex.modal,
    boxSizing: 'border-box',
    width: '100%',
    padding: theme.spacing(3, 3.5),
  },
  errorMessage: {},
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

const BottomErrorPanel = ({ classes, title, message, showClose, onClose }) => {
  return (
    <div className={classes.container}>
      {showClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => onClose?.()}
        >
          <CloseIcon />
        </IconButton>
      )}
      <ErrorMessage
        classes={{ root: classes.errorMessage }}
        message={message}
        title={title}
      />
    </div>
  );
};

BottomErrorPanel.propTypes = {
  title: PropTypes.string,
  message: PropTypes.node,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withStyles(styles)(BottomErrorPanel);
