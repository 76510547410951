import { withStyles, useTheme } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';

const styles = {
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: ({ align = 'center' }) => {
      switch (align) {
        case 'left':
          return 'flex-start';
        case 'right':
          return 'flex-end';
        default:
          return 'center;';
      }
    },
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    position: 'absolute',
    zIndex: 1,
  },
};

const IconPlacer = ({
  children,
  icon,
  iconLeftSpacing,
  iconRightSpacing,
  className,
  classes,
}) => {
  const theme = useTheme();

  if (iconLeftSpacing === undefined && iconRightSpacing === undefined) {
    iconRightSpacing = 1;
  }

  return (
    <span className={cx(className, classes.root)}>
      {children}
      <span
        className={classes.icon}
        style={{
          left:
            !iconRightSpacing &&
            iconLeftSpacing &&
            theme.spacing(iconLeftSpacing),
          right: iconRightSpacing && theme.spacing(iconRightSpacing),
        }}
      >
        {icon}
      </span>
    </span>
  );
};

export default withStyles(styles)(IconPlacer);
