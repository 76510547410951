import React, { useState } from 'react';

import { BottomErrorPanel } from '../../components/Errors';
import LoginScreen from '../../components/Login';
import { errorMessage } from './errors';

const View = ({ errorType, splash, ...props }) => {
  const errorToShow = errorMessage(errorType);
  const [errorDismissed, setErrorDismissed] = useState(false);

  return (
    <>
      <LoginScreen splash={!errorToShow && splash} {...props} />
      {!errorDismissed && errorToShow && (
        <BottomErrorPanel
          {...errorToShow}
          showClose
          onClose={() => setErrorDismissed(true)}
        />
      )}
    </>
  );
};

export default View;
