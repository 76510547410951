import React from 'react';
import { withStyles, Drawer } from '@material-ui/core';
import SelectHolder from './SelectHolder';

const TOP_BAR_HEIGHT = 105;
const paperStyles = {
  boxSizing: 'border-box',
  paddingBottom: `env(safe-area-inset-bottom)`,
};

const RegularDrawer = withStyles(
  theme => {
    const borderRadius = theme.shape.borderRadius * 3;
    return {
      paper: {
        ...paperStyles,
        left: `env(safe-area-inset-left)`,
        right: `env(safe-area-inset-right)`,
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        maxHeight: `calc(100vh - ${TOP_BAR_HEIGHT - borderRadius}px)`,
      },
    };
  },
  { name: 'LriRegularDrawer' }
)(Drawer);

const FullScreenDrawer = withStyles(
  {
    paper: {
      ...paperStyles,
      paddingLeft: `env(safe-area-inset-left)`,
      paddingRight: `env(safe-area-inset-right)`,
      height: '100%',
    },
  },
  { name: 'LriFullScreenDrawer' }
)(Drawer);

const FullScreenSelectHolder = withStyles({
  header: {
    marginLeft: `calc(-1 * env(safe-area-inset-left))`,
    marginRight: `calc(-1 * env(safe-area-inset-right))`,
    paddingTop: 'env(safe-area-inset-top)',
    '& .select-location-title-class': {
      '@media (max-width:375px)': {
        fontSize: '1.1rem'
      }
    }
  },
})(SelectHolder);

const SelectDrawer = ({
  children,
  closeContent,
  open,
  showBack = false,
  title,
  classes,
  onBack,
  onClose,
  fullScreen = false,
}) => {
  const DrawerComponent = fullScreen ? FullScreenDrawer : RegularDrawer;
  const SelectHolderComponent = fullScreen
    ? FullScreenSelectHolder
    : SelectHolder;

  return (
    <DrawerComponent anchor="bottom" open={open} onClose={onClose}>
      <SelectHolderComponent
        closeContent={closeContent}
        showBack={showBack}
        title={title}
        onBack={onBack}
        onClose={onClose}
        classes={classes}
      >
        {children}
      </SelectHolderComponent>
    </DrawerComponent>
  );
};

export default SelectDrawer;
