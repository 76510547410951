import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import KPIList from '../components/KPIList';
import { Text3 } from '../typography';

const sectionNameStyles = theme => ({
  root: {
    padding: `${theme.spacing(2.5) + 1}px 0 ${theme.spacing(1) + 1}px`,
    textAlign: 'center',
  },
});

const SectionName = withStyles(sectionNameStyles)(Text3);

const MetricsSection = ({ section, useLLY }) => {
  const { sectionName, metrics } = section;
  return (
    <>
      {sectionName && (
        <>
          <SectionName bold component="div">
            {sectionName}
          </SectionName>
        </>
      )}
      <KPIList metrics={metrics} useLLY={useLLY}></KPIList>
    </>
  );
};

MetricsSection.propTypes = {
  section: PropTypes.shape({
    metrics: KPIList.propTypes.metrics,
    sectionName: PropTypes.string,
  }).isRequired,
};

export default MetricsSection;
