import { Divider, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { CheckList } from '../components/CheckList';
import { allParams } from './data';

const styles = theme => ({
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

const LocationParamsSelect = ({ classes, className, onChange, ...props }) => {
  return (
    <div className={className}>
      {allParams.map((param, i) => (
        <Fragment key={param.name}>
          {i > 0 && <Divider className={classes.divider} />}
          <CheckList
            className={classes.checkList}
            heading={param.label}
            emptyLabel="All"
            items={param.options}
            value={props[param.name]}
            onChange={value => onChange?.(param.name, value)}
          />
        </Fragment>
      ))}
    </div>
  );
};

LocationParamsSelect.propTypes = {
  onChange: PropTypes.func,
};

export default withStyles(styles, { name: 'LriLocationParamsSelect' })(
  LocationParamsSelect
);
