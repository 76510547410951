import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    height: theme.spacing(4),
  },
});

const SectionSpacer = ({ classes }) => <div className={classes.root} />;

export default withStyles(styles, { name: 'LriSectionSpacer' })(SectionSpacer);
