import { index } from '../util/array';

const channelOptions = [
  { label: 'MAINLINE', value: 'mainline' },
  { label: 'OUTLET', value: 'outlet' },
];
const channel = {
  name: 'channel',
  label: 'Channel',
  options: channelOptions,
  byValue: index(channelOptions, 'value'),
};

const businessModelOptions = [
  { label: 'CONCESSION', value: 'concession' },
  { label: 'COMPANY OPERATED', value: 'company_operated' },
  { label: 'COMMISSIONAIRE', value: 'commissionaire' },
  { label: 'FRANCHISE', value: 'franchise' },
];

const businessModel = {
  name: 'businessModel',
  label: 'Business Model',
  options: businessModelOptions,
  byValue: index(businessModelOptions, 'value'),
};

export const params = { channel, businessModel };

export const allParams = [channel, businessModel];
