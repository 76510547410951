const DIMENSION_PREFIX = 'GA_DIM_';

export const getCustomMap = config =>
  Object.keys(config).reduce((customMap, key) => {
    if (key.startsWith(DIMENSION_PREFIX)) {
      customMap[config[key]] = key
        .substr(DIMENSION_PREFIX.length)
        .toLowerCase();
    }
    return customMap;
  }, {});
