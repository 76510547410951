import { useAsync as useAsyncBase } from 'react-async-hook';

import { noop } from './util/function';

/**
 * 'react-async-hook' does some setState stuff internally that means useAsync
 * will return a result for the previous parameters the first time they change.
 * Manually returning a loading state – if the params for the async result do
 * match the ones we passed – can prevent the UI from going briefly out of sync.
 */
export const useAsync = (asyncFunction, params, options) => {
  const { loading, error, result, execute, currentParams } = useAsyncBase(
    asyncFunction,
    params,
    options
  );
  if (currentParams?.some((param, i) => !Object.is(param, params[i]))) {
    return {
      loading: true,
      error: undefined,
      result: undefined,
      execute: noop,
    };
  }
  return { loading, error, result, execute };
};
