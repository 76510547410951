export const typography = {
  // fontSize: 10,
  htmlFontSize: 10,
  fontFamily: 'Inter, Arial',
  subtitle2: {
    fontSize: '1rem',
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 'bolder',
    fontStyle: 'normal',
    lineHeight: 2.8,
    letterSpacing: '0.05rem',
  },
  h6: {
    fontSize: '1.2rem',
    fontWeight: 'bolder',
    fontStyle: 'normal',
    lineHeight: 2.25,
    letterSpacing: '0.05rem',
  },
  body1: {
    fontSize: '1.2rem',
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.25,
    letterSpacing: '0.04rem',
  },
};
