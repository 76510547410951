import React from 'react';
import cx from 'clsx';
import { withStyles } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';

const styles = theme => ({
  root: {
    background: theme.palette.background.panel,
    borderRadius: '100%',
    fontSize: '1.8em',
    padding: 3,
  },
});

const ClickIndicator = ({ classes, className }) => (
  <MoreHoriz className={cx(className, classes.root)} fontSize="inherit" />
);
export default withStyles(styles, { name: 'LriClickIndicator' })(
  ClickIndicator
);
