import cx from 'clsx';
import { List, ListItem, ListItemText, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { Heading4 } from '../../typography';
import CheckListItem from './CheckListItem';

const styles = {
  root: {},
};

const CheckList = ({
  classes,
  className,
  emptyLabel = 'None',
  heading,
  items,
  onChange,
  value,
  ...props
}) => {
  const selectedItem = items.find(item => item.value === value);

  return (
    <List className={cx(classes.root, className)} {...props}>
      {heading && (
        <ListItem dense>
          <ListItemText>
            <Heading4 gray>{heading}</Heading4>
          </ListItemText>
        </ListItem>
      )}
      <CheckListItem
        checked={!selectedItem}
        onClick={() => onChange?.(undefined)}
      >
        {emptyLabel}
      </CheckListItem>
      {items.map(item => (
        <CheckListItem
          key={item.value}
          checked={item === selectedItem}
          onClick={() => onChange?.(item.value)}
        >
          {item.label}
        </CheckListItem>
      ))}
    </List>
  );
};

CheckList.propTypes = {
  className: PropTypes.string,
  emptyLabel: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.any,
    })
  ).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default withStyles(styles, { name: 'LriCheckList' })(CheckList);
