export class SecurityError extends Error {}

export class OidcError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
  }

  toString() {
    return `${this.code}: ${this.message}`;
  }
}
