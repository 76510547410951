import { isNullOrUndefined } from '../util/object';

/**
 * Reads the text content of an element and parses it.
 *
 * @param {string} selector
 *   A CSS selector.
 * @param {(value: string) => object} [parse=JSON.parse]
 *   A function to parse the text content of the selected element. Defaults to
 *   `JSON.parse`.
 * @returns {object | undefined}
 *   The parsed content or `undefined` if the element does not exist.
 */
function readConfigElement(selector, parse = JSON.parse) {
  const configEl = document.querySelector(selector);
  if (configEl) {
    return parse(configEl.textContent);
  }
  return;
}

/**
 * Fetch a resource and parse its content.
 *
 * @param {string} resource
 *   URL of the resource you want to fetch.
 * @param {(value: string) => object} parse
 *   A function to parse the response text. Defaults to `JSON.parse`.
 * @returns {Promise<object | undefined>}
 *   The parsed response or `undefined` if the server responded with a non-2xx
 *   status code.
 */
async function fetchConfigResource(resource, parse = JSON.parse) {
  const response = await fetch(resource);
  if (response.ok) {
    const data = await response.text();
    return parse(data);
  }
  return;
}

/**
 * Tries to read JSON configuration from a `#config` element and, if that fails,
 * tries to load configuration from the server at `/config.json`.
 *
 * @returns {Promise<object | undefined>}
 *   A configuration object or `undefined` if configuration couldn't be located.
 */
const readConfig = async () =>
  readConfigElement('#config') ||
  (await fetchConfigResource(process.env.PUBLIC_URL + '/config.json'));

/**
 * Check that a configuration object is valid.
 *
 * API_BASE_URL is the only config variable we definitely need. Add any others
 * to this routine as they get added.
 *
 * @param {*} config
 * @returns {boolean}
 */
const validateConfig = config => {
  if (isNullOrUndefined(config)) {
    return false;
  }

  try {
    // API_BASE_URL must be more than present, it has to be something we can hit
    // up with `fetch`.
    new URL(config.API_BASE_URL);
  } catch {
    return false;
  }

  return true;
};

export const loadConfig = async () =>
  readConfig().then(config => {
    if (!validateConfig(config)) {
      // `null` is a valid result from `JSON.parse`.
      const msg =
        config === undefined
          ? 'Unable to load configuration.'
          : 'The loaded configuration is invalid.';
      throw new ConfigError(msg, config);
    }
    return config;
  });

export class ConfigError extends Error {
  constructor(message, config) {
    super(message);
    this.config = config;
  }
}
