import { useAsync } from '../async';
import { useSecurity } from '../auth';
import { useConfig } from '../config';
import { NotOkError } from '../util/http';
import { CACHEDAPI } from './utils';
import {
  setLocalStorageApiCache,
  getLocalStorageApiCacheValue,
} from './localstorage';
import { filter, propEq, isEmpty } from 'ramda';
import { usePreferencesManager } from '../data/user-preferences';

const HTTP_STATUS_UNAUTHORIZED = 401;

const removeUser = async instance => {
  await instance.logoutRedirect();
};

const useData = (op, params = {}, opId = undefined) => {
  const { API_BASE_URL: baseUrl } = useConfig();
  const { isAuthenticated, instance, accessToken } = useSecurity();
  const pm = usePreferencesManager();
  const refreshDate = JSON.parse(pm.get('data_refresh_date') || '{}');
  const serializedParams = JSON.stringify(params);
  const apiKey = `${serializedParams}-api-${refreshDate?.current_date}=${opId}`;
  const filteredList = filter(propEq('name', opId), CACHEDAPI);
  const cachedAPIResult = getLocalStorageApiCacheValue(apiKey);
  if (opId === 'periods') {
    // const date1 = new Date().toLocaleString('en-US', {
    //   timeZone: 'America/New_York',
    // });
    refreshDate.period_updated = new Date().getTime();
    pm.set('data_refresh_date', JSON.stringify(refreshDate));
  }
  const shouldCache = !isEmpty(filteredList);
  // const isCached = (opId !== "periodsToCompare") && (!isEmpty(cachedAPIResult) && !cachedAPIResult.loading);
  const isCached = !isEmpty(cachedAPIResult) && !cachedAPIResult.loading;
  const {
    loading,
    error,
    result: data,
    execute: refetch,
  } = useAsync(() => {
    const fetchOptions = accessToken
      ? { headers: { Authorization: `Bearer ${accessToken}` } }
      : {};
    return op && !isCached
      ? op(baseUrl, JSON.parse(serializedParams), fetchOptions)
      : null;
  }, [op, baseUrl, accessToken, serializedParams]);

  if (
    error instanceof NotOkError &&
    error.response.status === HTTP_STATUS_UNAUTHORIZED &&
    isAuthenticated
  ) {
    console.log(
      'Unsetting the logged in user because an operation returned 401 Unauthorized.'
    );
    removeUser(instance);
  }

  if (isCached) {
    return cachedAPIResult;
  }
  const errorCode = error?.response?.status;
  if (
    shouldCache &&
    errorCode !== 404 &&
    errorCode !== 590 &&
    loading !== true
  ) {
    setLocalStorageApiCache(
      apiKey,
      JSON.stringify({ loading, error, data, refetch: () => refetch() })
    );
  }
  return { loading, error, data, refetch: () => refetch() };
};

export default useData;
