import { useData, getKpis, getRealTimeMetrics } from '../../api';
import defaultSort from '../utils/defaultSort';
import { transformMetrics } from '../utils/transforms';
import { useHandleNotGeneratedPeriod } from './useHandleNotGeneratedPeriod';
import { isEmpty } from 'lodash';

export default function useRealTimeMetricsData(
  locationId,
  locationParams,
  periodId,
  useUsdAmount,
  includeTaxes,
  useLLY
) {
  const kpis = useData(getKpis, {}, 'kpis');
  const metrics = useData(getRealTimeMetrics, {
    period: periodId,
    location: locationId,
    ...locationParams,
  });
  let data;
  if (!kpis.loading && !metrics.loading) {
    if (metrics.data && kpis.data) {
      data = transformMetrics({
        metricsResponse: metrics.data,
        kpiMetaResponse: kpis.data,
        useUsdAmount,
        includeTaxes,
        useLLY,
        periodId,
        realtimePage: true,
      });
      data.sort(defaultSort);
    }
  }
  useHandleNotGeneratedPeriod({ response: metrics, status: 0 });

  return {
    data,
    loading: kpis.loading || metrics.loading,
    error: kpis.error || metrics.error,
    refetch: () => {
      if (kpis.error) kpis.refetch();
      if (metrics.error || isEmpty(data)) metrics.refetch();
    },
  };
}
