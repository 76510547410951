import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, CardContent, withTheme } from '@material-ui/core';

import * as CustomPropTypes from '../../propTypes';
import { Text2 } from '../../typography';
import Card from './Card';
import ClickIndicator from './ClickIndicator';

const useStyles = makeStyles(theme => ({
  content: {
    minHeight: 112,
    boxSizing: 'border-box',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  clickIndicator: {
    position: 'absolute',
    right: theme.spacing(0.75),
    top: theme.spacing(0.75),
  },
}));

const CompareCard = ({ children, color, onClick }) => {
  const classes = useStyles();

  return (
    <Card color={color} onClick={onClick}>
      <ClickIndicator className={classes.clickIndicator} />
      <CardContent className={classes.content}>
        <Text2 bold align="center" component="div" variant="subtitle1">
          {children}
        </Text2>
      </CardContent>
    </Card>
  );
};

CompareCard.propTypes = {
  color: CustomPropTypes.themePaletteColor,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
};

export default withTheme(CompareCard);
