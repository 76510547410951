import { delimit as d } from './testUtils';

export const requirable = validate => {
  const f = function (props, propName) {
    if (props[propName] !== undefined && props[propName] !== null) {
      return validate.apply(this, arguments);
    }
  };

  f.isRequired = function (
    props,
    propName,
    componentName,
    location,
    propFullName
  ) {
    if (props[propName] === undefined || props[propName] === null) {
      return new Error(
        `The ${location} ${d(propFullName)} is marked as required in` +
          `${d(componentName)}, but its value is ${d(String(props[propName]))}.`
      );
    }

    return validate.apply(this, arguments);
  };

  return f;
};
