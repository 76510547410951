import { isNullOrUndefined } from './object';

/**
 * Identity function.
 *
 * Returns the first argument it receives.
 *
 * @param {*} object
 * @returns {*}
 */
export const identity = x => x;

/**
 * Does nothing (no operation).
 */
export const noop = () => {};

/**
 * Combines multiple functions into one function that applies any arguments to
 * all of the given functions and returns an array of results.
 *
 * @example
 *   const double = x => x + x;
 *   const square = x => x * x;
 *   const combined = applyAll(double, square);
 *   combined(3); // [6, 9]
 *
 * @param {function[]} fs
 * @returns {function}
 */
export const applyAll = (...fs) => (...args) => fs.map(f => f(...args));

/**
 * Combines multiple functions into one function that applies any arguments to
 * all of the given functions.
 *
 * Unlike `applyAll`, the returned function always returns `undefined` and
 * return values from the given functions are ignored. This inherently makes
 * `callAll` useful only for combining functions with side effects.
 *
 * @param {function[]} fs
 * @returns {function}
 */
export const callAll = (...fs) => (...args) => void applyAll(...fs)(...args);

/**
 * Create a debounced version of an async function that prevents invocations of
 * `f` while a previous invocation is still pending.
 *
 * @param {function} f
 * @returns {function}
 */
export const debounceAsync = f => {
  let pending;
  const reset = x => {
    pending = undefined;
    return x;
  };

  return (...args) => {
    if (pending === undefined) {
      pending = Promise.resolve(f(...args)).then(reset, reset);
    }
    return pending;
  };
};

export const maybe = (func, x) => {
  if (isNullOrUndefined(x)) {
    return x;
  }
  return func(x);
};
