import { filterMap } from '../../../util/array';

/**
 * Remove 'useless' locations from the root of the tree.
 *
 * A location is considered useless if it's marked as having no data and has
 * fewer than two 'useful' descendants.
 *
 * Warning: Does not check for cycles; ensure a valid tree is given.
 *
 * @param {TreeNode} node
 * @returns {TreeNode|undefined}
 */
const onlyUsefulLocations = node => {
  if (!node) {
    return;
  }

  let usefulChildren = [];
  if (Array.isArray(node.children)) {
    usefulChildren = filterMap(node.children, onlyUsefulLocations);
  }

  if (node.hasData === false && usefulChildren.length < 2) {
    return usefulChildren?.[0];
  }

  const copy = { ...node, children: usefulChildren };
  if (copy.children.length > 0) {
    copy.children.forEach(child => {
      child.parent = copy;
    });
  } else {
    delete copy.children;
  }
  delete copy.parent;
  return copy;
};

export default onlyUsefulLocations;
