import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Heading4, Text3 } from '../../typography';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  message: {
    '& p:first-child': {
      margin: theme.spacing(0),
    },
    '& p': {
      margin: theme.spacing(1, 0, 0),
    },
  },
  title: {
    marginBottom: theme.spacing(1),
  },
});

const ErrorMessage = ({ classes, title, message }) => (
  <div className={classes.root}>
    {title && <Heading4 className={classes.title}>{title}</Heading4>}
    {message && (
      <Text3 className={classes.message} component="div">
        {message}
      </Text3>
    )}
  </div>
);

ErrorMessage.propTypes = {
  message: PropTypes.node,
  title: PropTypes.string,
};

export default withStyles(styles, { name: 'LriErrorMessage' })(ErrorMessage);
