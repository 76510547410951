/**
 * @param {object} object
 * @returns {object}
 */
export const clone = object => ({ ...object });

/**
 * Creates an object by mapping the key/value pairs in `object` by passing them
 * to `f`.
 *
 * @param {object} object
 * @param {function([string, any]): [any, any]} f
 * @returns {object}
 */
export const mapEntries = (object, f) => {
  const entries = Object.entries(object);
  const mapped = entries.map(f);
  return Object.fromEntries(mapped);
};

/**
 * Create an object with the same keys as `object` and values generated by
 * passing the values of `object` through `f`.
 *
 * @param {object} object
 * @param {function(any): any} f
 * @returns {object}
 */
export const mapValues = (object, f) =>
  mapEntries(object, ([k, v]) => [k, f(v)]);

/**
 * Filter an object by passing its values through a predicate function.
 *
 * @param {object} object
 * @param {function(any): boolean} predicate
 * @returns {object}
 */
export const filterEntries = (object, predicate) =>
  Object.fromEntries(Object.entries(object).filter(predicate));

/**
 * Filter an object by passing its values through a predicate function.
 *
 * @param {object} object
 * @param {function(any): boolean} predicate
 * @returns {object}
 */
export const filterValues = (object, predicate) =>
  Object.fromEntries(Object.entries(object).filter(([, v]) => predicate(v)));

/**
 * @param {object} object
 * @param {...string} keys
 * @returns {object}
 */
export const omit = (object, ...keys) =>
  filterEntries(object, ([k]) => !keys.includes(k));

/**
 * @param {object} object
 * @param {...string} keys
 * @returns {object}
 */
export const pick = (object, ...keys) =>
  filterEntries(object, ([k]) => keys.includes(k));

/**
 * Create a function that renames a property in an object.
 *
 * The returned object is a shallow-cloned version of `object` with the property
 * `[from]` renamed to `[to]`. The `[from]` property will not be present in the
 * clone.
 *
 * @param {string} from
 * @param {string} to
 * @returns {(object: object) => object}
 */
export const renameProp = (from, to) => ({ [from]: value, ...x }) => ({
  ...x,
  [to]: value,
});

/**
 * @param {any} x
 * @returns {boolean}
 */
export const isNullOrUndefined = x => x === null || x === undefined;
