import React from 'react';
import { Typography, withStyles } from '@material-ui/core';

function createTypography(styles, name) {
  const StyledTypography = withStyles(
    { root: styles },
    { name: 'Lri' + name }
  )(Typography);
  StyledTypography.displayName = name;

  return ({ gray, style, caps = true, ...props } = {}) => (
    <StyledTypography
      style={{
        opacity: gray ? 0.65 : 'unset',
        textTransform: caps ? 'uppercase' : 'unset',
        ...style,
      }}
      {...props}
    />
  );
}

const createHeading = (styles, name) =>
  createTypography(
    {
      fontFamily: 'Greycliff CF',
      ...styles,
    },
    name
  );

const createBody = (styles, boldWeight, name) => {
  const StyledTypography = createTypography(
    {
      fontFamily: 'Inter',
      ...styles,
    },
    name
  );

  return ({ bold = false, caps = false, style, ...props }) => (
    <StyledTypography
      style={{
        fontWeight: bold ? boldWeight : 'unset',
        textTransform: caps ? 'uppercase' : 'unset',
        ...style,
      }}
      {...props}
    />
  );
};

export const Shout1 = createHeading(
  {
    fontWeight: 800,
    fontSize: 48,
    lineHeight: '96%',
    letterSpacing: '-0.02em',
  },
  'Shout1'
);

export const Shout2 = createHeading(
  {
    fontWeight: 800,
    fontSize: 40,
    lineHeight: '96%',
    letterSpacing: '-0.02em',
  },
  'Shout2'
);

export const Heading1 = createHeading(
  {
    fontWeight: 800,
    fontSize: '3.2rem',
    lineHeight: '110%',
    textTransform: 'uppercase',
  },
  'Heading1'
);

export const Heading2 = createHeading(
  {
    fontWeight: 800,
    fontSize: '2.4rem',
    lineHeight: '110%',
    textTransform: 'uppercase',
  },
  'Heading2'
);

export const Heading3 = createHeading(
  {
    fontWeight: 600,
    fontSize: '1.6rem',
    lineHeight: '120%',
    textTransform: 'uppercase',
    fontFamily: 'Inter',
  },
  'Heading3'
);

export const Heading4 = createHeading(
  {
    fontWeight: 600,
    fontSize: '1.4rem',
    lineHeight: '120%',
    textTransform: 'uppercase',
    fontFamily: 'Inter',
    margin: 0,
  },
  'Heading4'
);

export const Text1 = createBody(
  {
    fontFamily: 'Inter',
    fontWeight: 'normal',
    fontSize: '1.6rem',
    lineHeight: '140%',
    letterSpacing: '0.01em',
  },
  600,
  'Text1'
);

export const Text2 = createBody(
  {
    fontWeight: 'normal',
    fontSize: '1.4rem',
    lineHeight: '130%',
    letterSpacing: '0.01em',
  },
  600,
  'Text2'
);

export const Text3 = createBody(
  {
    fontWeight: 'normal',
    fontSize: '1.2rem',
    lineHeight: '120%',
    letterSpacing: '0.01em',
  },
  600,
  'Text3'
);

export const Note = createBody(
  {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '120%',
    letterSpacing: '0.04em',
  },
  'bold',
  'Note'
);
