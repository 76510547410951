export const CACHEDAPI = [
  {
    name: 'kpis',
    ttl: '',
  },
  {
    name: 'metrics',
    ttl: '',
  },
  {
    name: 'metricsA',
    ttl: '',
  },
  {
    name: 'metricsB',
    ttl: '',
  },
  {
    name: 'products',
    ttl: '',
  },
  {
    name: 'sales',
    ttl: '',
  },
];
